import axios from "axios";
import React, { useState, useEffect } from "react";
import aboutus from "../assets/images/team-hero.jpeg";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TeamCard from "../components/TeamCard";
import { t } from "i18next";
import Menu from "../components/Menu";
import SearchMain from "../components/SearchMain";
import { useLocation } from "react-router-dom";

const Team = () => {
  const lang = window.localStorage.getItem("apnode_lang");
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const [executives, setExecutives] = useState([]);
  const location = useLocation();
  var element;

  if (location.hash) {
    console.log(location.hash);
    element = document.querySelector(location.hash);
  }

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [element]);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/executives/${lang}`,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.results);
        setExecutives(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  const parliamentFilter = (data) => {
    return data.member_type == "parliament";
  };

  const institutionFilter = (data) => {
    return data.member_type == "institutional_member";
  };

  return (
    <div className="bg-white">
      {show && <Menu color="white" show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div>
        <img className="mx-auto w-screen" src={aboutus} />
      </div>
      <div className="xl:w-[1200px] lg:w-[950px] w-full mx-auto  bg-white translate-y-[-76px] gap-[90px] pt-[25px]">
        <h2 className="text-appPText  font-sBold xl:text-4xl lg:text-3xl text-[28px]  text-center uppercase">
          {t("Meet the Executive")}
        </h2>
        <p className="text-center text-appText">
          {t("We deliver the best job with the best team")}
        </p>

        {/* lg:grid lg:grid-cols-[300px_300px_300px] justify-center xl:grid-cols-3 block w-full  gap-[24px] lg:w-[936px] mt-9 mx-auto */}
        <div className="team-grid">
          {/* {executives.filter((ele, i) => (
            <TeamCard data={ele} key={i} />
          ))} */}
          {executives.filter(parliamentFilter).map((ele, i) => (
            <TeamCard data={ele} key={i} />
          ))}
        </div>
        {/* 
        <h2 className="text-appPText  font-sBold xl:text-4xl lg:text-3xl text-[28px]  text-center uppercase">
          {t("Meet the Institutional Members")}
        </h2> */}
        {/* lg:grid lg:grid-cols-[300px_300px_300px] xl:grid-cols-3 block w-full  gap-[24px] lg:w-[936px] mt-9 mx-auto */}
        <div className="team-grid">
          {executives.filter(institutionFilter).map((ele, i) => (
            <TeamCard data={ele} key={i} />
          ))}
        </div>

        {/* <h1
          id="secretariat"
          className="text-center mb-[50px] text-4xl font-sBold"
        >
          {t("SECRETARIAT")}
        </h1> */}
        {/* <p className="px-4 mb-6">
          {t(
            "The Secretariat is the administrative and operational arm of APNODE and executes the day-to-day operations of the Network under the guidance of the EC. In addition, the Secretariat administers the Network, ensures communication with the members, organizes the APNODE Annual General Meetings and coordinates the bimonthly virtual meetings of the APNODE Executive Committee."
          )}
          <br />
          <br />
          {t(
            "The Independent Development Evaluation (IDEV) at the AfDB, in line with its mandate to nurture an evaluation culture across the Bank’s Regional Member Countries, and as part of its evaluation capacity development work program, has hosted the Secretariat of APNODE since its inception."
          )}
        </p> */}
      </div>
      <Footer />
    </div>
  );
};

export default Team;
