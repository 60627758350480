/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import React, { useContext, useState, useEffect } from "react";
import dayjs from "dayjs";

// import { DatePicker } from "../components/DatePicker/DatePicker.tsx";
// import { CalenderPicker } from "../components/CalenderPicker/CalenderPicker.tsx";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import EventCard from "../components/EventCard";
import { getMonth } from "../components/util";
import CalendarHeader from "../components/Calender/CalenderHeader";
import Sidebar from "../components/Calender/Sidebar";
import Month from "../components/Calender/Month";
import EventModal from "../components/Calender/EventModal";
import GlobalContext from "../context/GlobalContext";
import { t } from "i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import SearchMain from "../components/SearchMain";
import Menu from "../components/Menu";
import { useLocation } from "react-router-dom";

const Events = () => {
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal } = useContext(GlobalContext);
  const [teamEvents, setTeamEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const lang = window.localStorage.getItem("apnode_lang");
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const { eventDate } = useSelector((state) => state.date);
  const [showSearch, setShowSearch] = useState(false);
  const location = useLocation();
  var element;

  if (location.hash) {
    element = document.querySelector(location.hash);
  }

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [element]);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  useEffect(() => {
    var date = new Date();
    date = date.toLocaleDateString().split("/");
    var newDate = [date[2], date[0], date[1]].join("-");
    var config = {
      method: "get",
      url: `/v1/events/general/events/${lang}/${eventDate}`,
    };
    axios(config)
      .then(function (response) {
        setTeamEvents(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [eventDate]);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/events/general/upcoming/events/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setUpcomingEvents(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/events/general/past/events/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setPastEvents(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  return (
    <div className="bg-white">
      {show && <Menu show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div className="bg-navbarBg">
        <div className="xl:max-w-[1200px] mx-auto pt-10">
          <h2 className="font-sBold text-2xl pb-[12px]  xl:px-0 px-3">
            {t("Events")}
          </h2>
        </div>
      </div>
      <div className="sharp-bg-sm-white mb-[100px]">
        <div className="xl:max-w-[1200px] bg-white  mx-auto xl:px-[35px] px-4 py-[31px]">
          <div className=" flex flex-col">
            <div className="grid lg:grid-cols-[30%_70%] gap-4">
              <div className="mx-auto">
                <div className="lg:w-[304px] w-full">
                  <Sidebar />
                  <div className="mt-[18px]">
                    {teamEvents.length > 0
                      ? teamEvents.map((ele, i) => (
                          <EventCard key={i} event={ele} />
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-2  grid-cols-1 w-full">
                <div>
                  <h3 className="text-appPText lg:text-[26px] text-[25px] text-center mb-[6px] font-sBold  uppercase">
                    {t("Upcoming Events")}
                    <hr
                      id="upcoming"
                      width="80%"
                      className="mx-auto my-8 border border-buttonColor bg-buttonColor"
                    />
                  </h3>
                  <div className="mt-[18px] mx-auto">
                    {upcomingEvents.length > 0
                      ? upcomingEvents.map((ele, i) => (
                          <EventCard key={i} event={ele} />
                        ))
                      : null}
                  </div>
                </div>
                <div>
                  <h3
                    id="past"
                    className="text-appPText text-center lg:text-[26px] text-[25px] mb-[6px] font-sBold  uppercase"
                  >
                    {t("Past Events")}
                  </h3>
                  <hr
                    width="80%"
                    className="mx-auto my-8 border border-buttonColor bg-buttonColor"
                  />
                  <div className="mt-[18px]">
                    {pastEvents.length > 0
                      ? pastEvents.map((ele, i) => (
                          <EventCard key={i} event={ele} />
                        ))
                      : null}
                  </div>
                </div>
              </div>
              {/* <Month month={currenMonth} /> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Events;
