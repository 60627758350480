import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import Navbar from "../components/Navbar";
import SearchMain from "../components/SearchMain";
import resourcesImg from "../assets/images/resources.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import SeeAllCard from "../components/SeeAllCard";
import VideoCard from "../components/Resources/VideoCard";
import { t } from "i18next";

const SeeAll = () => {
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [resources, setResources] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [results, setResults] = useState();
  let { type } = useParams();
  const [selected, setSelected] = useState(type);
  const lang = window.localStorage.getItem("apnode_lang");

  useEffect(() => {
    if (type === "videos") {
      setSelected("video");
    } else {
      setSelected(type);
    }
  }, [type]);

  useEffect(() => {
    var config = {
      method: "get",
      url:
        type === "videos"
          ? `/v1/resources/general/categories/videos/${lang}/`
          : `/v1/resources/general/${type}/${lang}`,
    };

    axios(config)
      .then(function (response) {
        if (type === "videos") {
          console.log(Object.entries(response.data));
          setResources(Object.entries(response.data));
        } else {
          setResources(response.data.results);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url:
        selected === "video"
          ? `/v1/resources/general/categories/videos/${lang}/`
          : `/v1/resources/general/${selected}/${lang}`,
    };
    axios(config)
      .then(function (response) {
        console.log("using type", selected);
        if (selected === "video") {
          setResources(Object.entries(response.data));
        } else {
          console.log(response.data.results);
          setResources(response.data.results);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [selected]);

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };
  const handleShowSearch = () => {
    setShowSearch(true);
  };

  var cats = [
    {
      value: "official-document",
      text: t("Official Documents"),
    },

    {
      value: "publications",
      text: t("Publications"),
    },
    {
      value: "report",
      text: t("Administrative Reports"),
    },
    {
      value: "video",
      text: t("Videos"),
    },
  ];

  const handleSelected = (e) => {
    console.log(e.target.value);
    setSelected(e.target.value);
  };

  return (
    <div className="bg-white">
      {show && <Menu color="white" show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div>
        <img className="mx-auto w-screen" src={resourcesImg} />
      </div>
      <div className="xl:w-[1200px]  mt-10 p lg:w-[950px] xl:px-0 px-4 w-full mx-auto  bg-white pt-[48px] mb-5">
        <div className="block mb-96">
          <div className="xl:w-[1200px] lg:w-[950px] grid lg:grid-cols-[30%_70%] block gap-[30px] w-full mx-auto xl:px-0 px-5 pt-[0px] ">
            <div className=" ">
              <h1 className="text-[25px] uppercase font-sBold mb-2">
                {t("Types")}
              </h1>
              <div className="border-2 rounded-sm border-buttonColor p-4">
                {cats.map((ele, i) => (
                  <div key={i} className="flex items-center mb-4 border-r ">
                    <input
                      value={ele.value}
                      type="radio"
                      name="type"
                      checked={selected === ele.value}
                      onChange={(e) => handleSelected(e)}
                      className="w-5 h-5  radio-item mr-2"
                    />
                    <label class="block mb-2 text-sm font-sLight text-[#555555] drop">
                      {t(ele.text)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="">
              {selected === "video" || type == "videos" ? (
                <div className="grid  lg:grid-cols-1 ">
                  {resources.length > 0
                    ? resources.map((ele, i) => (
                        <>
                          {ele[1]?.length > 0 ? (
                            <>
                              <h3
                                className={`text-appPText text-2xl font-sBold uppercase mb-2   ${
                                  i === 1 ? "mt-0 " : "mt-5"
                                }`}
                              >
                                {t(ele[0])}
                              </h3>
                              <div className="grid  lg:grid-cols-2 gap-[30px]">
                                {ele[1]?.map((elem, j) => (
                                  <VideoCard
                                    type="see all"
                                    data={elem}
                                    key={j}
                                  />
                                ))}
                              </div>
                              <hr
                                width="80%"
                                className="mx-auto lg:mt-10 mb-4 mt-3 border border-buttonColor bg-buttonColor"
                              />
                            </>
                          ) : null}
                        </>
                      ))
                    : null}
                </div>
              ) : (
                <div className="grid  lg:grid-cols-2 gap-[30px]">
                  {resources.map((ele, i) => (
                    <SeeAllCard type={selected} data={ele} key={i} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SeeAll;
