import React from "react";
import { useTranslation } from "react-i18next";

const Interested = ({ link }) => {
  const { t } = useTranslation();

  return (
    <div className="py-[70px] mt-10 bg-appBlack">
      <div className="lg:w-[900px] md:w-[700px] w-full block  mx-auto text-center items-center gap-[60px] px-5">
        <div>
          <h3 className="font-sm lg:text-[38px] text-[25px] uppercase   font-sBold text-white mb-[10px]">
            {t("Interested in joining our Network?")}
          </h3>
          <p className="text-white  lg:text-base text-[14px]">
            {t("Request membership at APNODE to join the family.")}
          </p>
        </div>
        <div className="w-full">
          {link ? (
            <a
              href="/membership/form"
              className="border mx-auto border-buttonColor mt-5 block px-[30px] py-[9px] w-fit text-white lg:text-base text-[14px]"
            >
              {t("Join now")}
            </a>
          ) : (
            <a
              href="/membership"
              className="border mx-auto border-buttonColor mt-5 block px-[30px] py-[9px] w-fit text-white lg:text-base text-[14px]"
            >
              {t("Join now")}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Interested;
