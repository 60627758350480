import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import resourcesImg from "../assets/images/resources.png";
import Footer from "../components/Footer";
import PdfCard from "../components/Resources/PdfCard";
import ReportCard from "../components/Resources/ReportCard";
import VideoCard from "../components/Resources/VideoCard";
import n1 from "../assets/images/n1.png";
import n2 from "../assets/images/n2.png";
import EventCard from "../components/EventCard";
import axios from "axios";
import { t } from "i18next";
import Menu from "../components/Menu";
import { useLocation } from "react-router-dom";
import SearchMain from "../components/SearchMain";
const Resources = () => {
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [reports, setReports] = useState([]);
  const [publications, setPublications] = useState([]);
  const [videos, setVideos] = useState([]);
  const [news, setNews] = useState([]);
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const lang = window.localStorage.getItem("apnode_lang");
  const location = useLocation();
  var element;

  if (location.hash) {
    console.log(location.hash);
    element = document.querySelector(location.hash);
  }

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [element]);
  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/resources/general/official-document/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setResources(response.data.results.splice(0, 6));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/events/general/events/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setEvents(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/resources/general/report/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setReports(response.data.results.splice(0, 6));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/resources/general/publications/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setPublications(response.data.results.splice(0, 6));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/resources/general/video/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setVideos(response.data.results.splice(0, 6));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/news/general/news/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setNews(response.data.results.splice(0, 2));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const truncate = (str) => {
    return str.length > 300 && str ? str.substring(0, 280) + "..." : str;
  };

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };
  const handleShowSearch = () => {
    setShowSearch(true);
  };

  return (
    <div className="bg-white">
      {show && <Menu color="white" show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div>
        <img className="mx-auto w-screen" src={resourcesImg} />
      </div>
      <div className="xl:w-[1200px] lg:w-[950px] xl:px-0 px-4 w-full mx-auto  bg-white pt-[48px]">
        <div
          id="official"
          className="flex w-full justify-between items-center mb-4"
        >
          <h2 className="text-appPText uppercase text-2xl font-sBold ">
            {t("Official Documents")}
          </h2>
          <a
            href="/resources/official-document"
            className="text-appText font-sMedium w-fit border-b-2 pb-1 border-b-appText block cursor-pointer"
          >
            {t("See all")}
          </a>
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 gap-[30px]">
          {resources.map((ele, i) => (
            <PdfCard data={ele} key={i} />
          ))}
        </div>

        <hr
          width="80%"
          className="mx-auto lg:mt-20 mt-10 border border-buttonColor bg-buttonColor"
        />

        <div
          id="reports"
          className="flex w-full justify-between items-center lg:mt-[70px] mt-10 mb-4"
        >
          <h2 className="text-appPText uppercase text-2xl font-sBold ">
            {t("Administrative Reports")}
          </h2>
          <a
            href="/resources/report"
            className="text-appText font-sMedium w-fit border-b-2 pb-1 border-b-appText block cursor-pointer"
          >
            {t("See all")}
          </a>
        </div>

        <div className=" grid xl:grid-cols-3 lg:grid-cols-3 gap-[30px]">
          {reports.map((ele, i) => (
            <PdfCard data={ele} key={i} />
          ))}
        </div>

        <hr
          width="80%"
          className="mx-auto lg:mt-20 mt-10 border border-buttonColor bg-buttonColor"
        />

        <div
          id="publications"
          className="flex w-full justify-between items-center lg:mt-[70px] mt-10 mb-4"
        >
          <h2 className="text-appPText uppercase text-2xl font-sBold ">
            {t("Publications")}
          </h2>
          <a
            href="/resources/publications"
            className="text-appText font-sMedium w-fit border-b-2 pb-1 border-b-appText block cursor-pointer"
          >
            {t("See all")}
          </a>
        </div>

        <div className="grid xl:grid-cols-3 lg:grid-cols-3 gap-[30px]">
          {publications.map((ele, i) => (
            <PdfCard data={ele} key={i} />
          ))}
        </div>

        <hr
          width="80%"
          className="mx-auto lg:mt-20 mt-10 border border-buttonColor bg-buttonColor"
        />

        <div
          id="videos"
          className="flex w-full justify-between items-center lg:mt-[70px] mt-10 mb-4"
        >
          <h2 className="text-appPText text-2xl font-sBold uppercase ">
            {t("Videos")}
          </h2>
          <a
            href="/resources/videos"
            className="text-appText font-sMedium w-fit border-b-2 pb-1 border-b-appText block cursor-pointer"
          >
            {t("See all")}
          </a>
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 gap-[30px] mb-7">
          {videos.map((ele, i) => (
            <VideoCard type="see all" data={ele} key={i} />
          ))}
        </div>

        <hr
          width="80%"
          className="mx-auto lg:mt-20 mt-10 border border-buttonColor bg-buttonColor mb-6"
        />

      
        {/* 
        <div className="flex w-full justify-between items-center lg:mt-[70px] mt-10 mb-4">
          <h2 className="text-appPText text-2xl font-sBold uppercase ">
            {t("News")}
          </h2>
          <a
            href="/news"
            className="text-appText font-sMedium w-fit border-b-2 pb-1 border-b-appText block cursor-pointer"
          >
            {t("See all")}
          </a>
        </div> */}

        {/* <div className="grid xl:grid-cols-2 grid-cols-1 gap-[47px]">
          {news.map((ele, i) => (
            <div key={i}>
              <img src={ele.image} />
              <div className="mt-[40px] text-[11px]  flex">
                {ele?.categories.map((ele, i) => (
                  <p className="font-sSemiBold mr-[20px]">{t(ele.name)}</p>
                ))}
                <p className="text-formText ">
                  {new Date(ele.created_at).toDateString()}
                </p>
              </div>
              <div className="mt-[17px]">
                <h6 className="text-2xl font-sSemiBold">{ele.title}</h6>
                <p
                  dangerouslySetInnerHTML={{
                    __html: truncate(ele?.description),
                  }}
                  className="text-appText text-[15px] mt-[8px]"
                ></p>
                <a
                  href={`/news/${ele?.slug}`}
                  className="text-buttonColor mt-[30px] font-sMedium w-fit border-b-2 pb-1 border-b-buttonColor block cursor-pointer"
                >
                  {t("Read More")}
                </a>
              </div>
            </div>
          ))}
        </div> */}
        {/* 
        <hr
          width="80%"
          className="mx-auto lg:mt-20 mt-10 border border-buttonColor bg-buttonColor"
        />

        <div className="flex w-full justify-between items-center lg:mt-[70px] mt-10 mb-4">
          <h2 className="text-appPText text-2xl font-sBold uppercase ">
            {t("Events")}
          </h2>
          <a
            href="/events"
            className="text-appText font-sMedium w-fit border-b-2 pb-1 border-b-appText block cursor-pointer"
          >
            {t("See all")}
          </a>
        </div>
        <div className="grid xl:grid-cols-4 grid-cols-1  gap-[30px] mb-20">
          {events.map((ele, i) => (
            <EventCard event={ele} key={i} />
          ))}
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default Resources;
