import { t } from "i18next";
import React from "react";
import { ReactComponent as Close } from "../assets/svgs/cancel.svg";

const CountryModal = ({ setShowCountryModal, country, content }) => {
  return (
    <div
      id="search-main"
      className="overlay-modal w-screen flex justify-center items-center h-screen z-20 fixed top-0 p-5"
    >
      <div className="w-[1150px] rounded-md">
        <div className="bg-white py-[23px] pt-[10px] px-[26px] cursor-pointer min-h-[200px] max-h-[800px] overflow-scroll">
          <div className="flex justify-between items-center mb-3  border-b-2">
            <div>
              <h2 className="font-sBold uppercase ">{t(country)}</h2>
            </div>
            <Close onClick={() => setShowCountryModal()} className="w-6" />
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default CountryModal;
