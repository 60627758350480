import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import i14 from "../assets/images/2014.png";
import i15 from "../assets/images/2015.png";
import i16 from "../assets/images/2016.png";
import i17 from "../assets/images/2017.png";
import i18 from "../assets/images/2018.png";
import i19 from "../assets/images/2019.png";
import i20 from "../assets/images/2020.png";
import i21 from "../assets/images/2021.png";
import Footer from "../components/Footer.js";
import { t } from "i18next";
import axios from "axios";
import Menu from "../components/Menu";
import SearchMain from "../components/SearchMain";
const History = () => {
  const [history, setHistory] = useState([]);
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const lang = window.localStorage.getItem("apnode_lang");
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    var config = {
      url: `/v1/apnode/general/history/${lang}`,
    };
    axios(config)
      .then(function (response) {
        var res = response.data.results;
        setHistory(res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {};

  return (
    <div className="bg-white">
      {show && <Menu show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div className="bg-navbarBg">
        <div className="xl:max-w-[1200px] mx-auto pt-10">
          <h2 className="font-sBold lg:text-[36px] text-[25px] pb-[12px] xl:px-0 px-3">
            {t("APNODE Over The Years")}
          </h2>
        </div>
      </div>
      <div className="sharp-bg-sm-white mb-20">
        <div className="xl:max-w-[1200px]  w-full mx-auto bg-white  gp-[90px] pt-[25px] block xl:pl-[62px] pl-[20px] pr-[10px] py-[15px]">
          <div>
            {/* 2014 */}

            {history.map((ele, i) => (
              <div
                key={i}
                className={`grid grid-cols-[49%_2%_49%] xl:pl-[90px] pl-0 mt-10 `}
              >
                {i % 2 === 0 ? (
                  <div className="pr-[30px]">
                    <a className="mt-10" href={ele.link}>
                      <img src={ele.image} className="w-[296px]" />
                    </a>
                  </div>
                ) : (
                  <a href={ele.link} className="xl:pl-0 pl-4">
                    <p className="font-league  text-[75px] ">{ele.date}</p>
                    <p className="text-buttonColor font-sMedium text-2xl">
                      {ele.title}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: ele.description,
                      }}
                    ></p>
                    {/* <ul className="text-appText leading-6 xl:text-base text-sm">
                      {ele.description.split("\n").map((elem, j) => (
                        <li key={j} className="history-list list-disc">
                          {elem}
                        </li>
                      ))}
                    </ul> */}
                  </a>
                )}

                <div className="w-[1px] bg-buttonColor flex flex-col items-center">
                  <div className="w-[1px] bg-red-400 h-[30%]"></div>
                  <div className="bg-white w-[24px] h-[24px] rounded-full border border-buttonColor  flex items-center justify-center">
                    <div className="w-4 h-4 bg-buttonColor rounded-full"></div>
                  </div>
                  <div></div>
                </div>
                {i % 2 === 0 ? (
                  <a href={ele.link} className="pl-[30px]">
                    <p className="font-league  text-[75px] ">{ele.date}</p>
                    <p className="text-buttonColor font-sMedium text-2xl">
                      {ele.title}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: ele.description,
                      }}
                    ></p>
                    {/* <ul className="text-appText leading-6 xl:text-base text-sm">
                      {ele.description.split("\n").map((elem, j) => (
                        <li key={j} className="history-list list-disc">
                          {elem}
                        </li>
                      ))}
                    </ul> */}
                  </a>
                ) : (
                  <div className="pl-[30px]">
                    <a className="mt-10 block" href={ele.link}>
                      <img src={ele.image} className=" w-[296px]" />
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default History;
