import React, { useEffect, useState } from "react";
import { ReactComponent as LinkedIn } from "../assets/svgs/linkedin.svg";
import { ReactComponent as Youtube } from "../assets/svgs/youtube.svg";
import { ReactComponent as Twitter } from "../assets/svgs/twitter.svg";
import { ReactComponent as Facebook } from "../assets/svgs/facebook.svg";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo.png";
import axios from "axios";
import { BallSpinner } from "react-spinners-kit";

const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState({});

  const submit = () => {
    setLoading(true);
    const data = JSON.stringify({
      email,
    });
    var config = {
      method: "post",
      url: `/v1/apnode/general/mailinglists/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setText(response);
        setLoading("");
        setEmail("");
        setTimeout(() => {
          setText("");
        }, 3000);
      })
      .catch(function (error) {
        setLoading(false);
        setText("Subscription Failed");
        setTimeout(() => {
          setText("");
        }, 3000);
        console.log(error);
      });
  };
  return (
    <footer className="bg-appPText py-[50px] mt-10  ">
      <div className="xl:max-w-[1200px] lg:max-w-[950px] lg:text-[14px] md:block xl:text-left text-center sm:block px-4 xl:grid xl:grid-cols-[15%_60%_25%] mx-auto gap-2">
        <div className="xl:flex xl:justify-start">
          <ul className="p-0 text-white font-sLight lg:mb-[24px] mb-[24px]">
            <h6 className="font-sBold  text-white lg:mb-[24px] mb-[24px] lg:text-base text-[14px]">
              {t("Contact Us")}
            </h6>
            {/* <li className="mb-[11px] text-white lg:text-base text-[14px]">
              APNODE Secretariat c/o Independent Development Evaluation (IDEV)
            </li>
            <li className="mb-[11px] lg:text-base text-[14px]">
              African Development Bank
            </li>
            <li className="mb-[11px] lg:text-base text-[14px]">
              01 BP 1387 Abidjan 01 Côte d’Ivoire
            </li>
            <li className="mb-[11px] lg:text-base text-[14px]">
              Email: apnode@afdb.org
            </li>
            <li className="mb-[11px] lg:text-base text-[14px]">
              +225 27 20 26 47 90 / +225 27 20 26 45 33 l
            </li> */}
            <li className="mb-[16px]">
              <a href="/contact-us">{t("Address")}</a>
            </li>

            <li className="mb-[16px]">
              <a href="mailto:apnode@afdb.org">{t("Email")}</a>
            </li>

            <li className="mb-[16px]">
              <a href="tel:+2252720264790">{t("Phone")}</a>
            </li>

            {/* <li className="mb-[16px]">
              <a href="/events">{t("Events")}</a>
            </li>

            <li className="mb-[16px]">
              <a href="/news">{t("News")}</a>
            </li> */}
          </ul>
        </div>
        <div className="lg:flex block justify-around">
          <div>
            <h6 className="font-sBold text-white lg:text-base text-[14px] mb-[24px]">
              {t("Navigation")}
            </h6>
            <ul className="text-white font-sLight lg:text-base text-[14px]">
              <li className="mb-[16px]">
                <a href="/">{t("Home")}</a>
              </li>

              <li className="mb-[16px]">
                <a href="/membership">{t("Membership")}</a>
              </li>

              <li className="mb-[16px]">
                <a href="/resources">{t("Resources")}</a>
              </li>

              <li className="mb-[16px]">
                <a href="/events">{t("Events")}</a>
              </li>

              <li className="mb-[16px]">
                <a href="/news">{t("News")}</a>
              </li>
            </ul>
          </div>
          <div className="lg:mt-0 mt-10 lg:text-base text-[14px]">
            <ul className="text-white font-sLight">
              <h6 className="font-sBold  text-white lg:mb-[24px] mb-[24px] lg:text-base text-[14px]">
                {t("Resources")}
              </h6>
              <li className="mb-[16px]">
                <a href="/resources#official">{t("Official Documents")}</a>
              </li>
              <li className="mb-[16px]">
                <a href="/resources#reports">{t("Reports")}</a>
              </li>
              <li className="mb-[16px]">
                <a href="/resources#publication">{t("Publications")}</a>
              </li>
              <li className="mb-[16px]">
                <a href="/resources#videos">{t("Videos")}</a>
              </li>
              {/* <li className="mb-[16px]">
                <a href="/faq">{t("FAQ")}</a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="lg:mt-0 mt-10 lg:text-base text-[14px]">
          <h6 className="font-sBold text-white mb-[24px] sm:items-center sm:justify-center ">
            {t("Our Socials")}
          </h6>
          <div className="flex justify-center xl:justify-start">
            <a
              target="_blank"
              href="https://www.linkedin.com/groups/8734908/"
              className="w-[34px] h-[34px] border border-white rounded-full flex justify-center items-center"
            >
              <LinkedIn className="footer-link" />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/APNODE_Official"
              className="w-[34px] h-[34px] border border-white rounded-full mx-[22px] flex justify-center items-center"
            >
              <Twitter className="footer-link" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=11CrvjtAw6E&list=PLJhooJc53y8XbLiQsc2yqPS0q3oR4MIPT"
              className="w-[34px] h-[34px] border border-white rounded-full mr-[22px] flex justify-center items-center"
            >
              <Youtube stroke="#fff" className="footer-link" />
            </a>
            {/* <a className="w-[34px] h-[34px] border border-white rounded-full flex justify-center items-center">
              <Facebook className="footer-link" />
            </a> */}
          </div>
          <p className="text-white font-sMedium lg:text-base text-[14px] mt-[16px]">
            {t("Subscribe To Our Mailing List")}
          </p>

          <form className=" mt-[16px] flex">
            <input
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              className=" border text-sm font-sLight border-formBorder  lg:w-[500px]  focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
              placeholder={t("Email")}
            />
          </form>
          <button
            onClick={submit}
            className={`border
                  ${
                    loading
                      ? "opacity-60 cursor-not-allowed"
                      : "opacity-100 cursor-pointer"
                  }
                  border-buttonColor text-buttonColor  px-[35px] py-[9px] mt-4 `}
          >
            {loading ? <BallSpinner color="#EC892A" /> : t("Subscribe")}
          </button>
          {text && (
            <p
              className={`text-sm  mt-2 ml-2 ${
                text?.status == 201 ? "text-green-600" : "text-red-600"
              }  `}
            >
              {text?.data?.detail}
            </p>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
