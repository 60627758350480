import { t } from "i18next";
import React, { useState } from "react";
import { ReactComponent as Close } from "../assets/svgs/cancel.svg";
import cfa from "../assets/images/cfa.png";
const PaymentModal = ({ setShowPayment }) => {
  const [selected, setSelected] = useState("");
  // options with default values
  const options = {
    placement: "bottom-right",
    backdrop: "dynamic",
    backdropClasses:
      "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
    onHide: () => {
      console.log("modal is hidden");
    },
    onShow: () => {
      console.log("modal is shown");
    },
    onToggle: () => {
      console.log("modal has been toggled");
    },
  };

  const dollars = `JPMORGAN CHASE BANK, NA\n
    270 PARK AVENUE\n
    17TH FLOOR\n
    NEW YORK, NEW YORK 10017-2070\n
    <b><u>ACCOUNT NAME</u>: AFRICAN DEVELOPMENT BANK</b>\n
    <b><u>ACCOUNT NUMBER</u>:  400052563</b>\n
    FED WIRE ABA: 021000021\n
    SWIFT BIC:  CHASUS33`;

  const rand = `STANDARD BANK OF SOUTH AFRICA LIMITED,\n
    The Standard Bank Centre, Floor 8: 5, S\n
    Johannesburg, 2001\n
    Menlyn (Branch code 012345)\n
    <b><u>SWIFT CODE:</u> SBZAZAJJ</b>\n
    <b><u>ACCOUNT NO.</u> 7117561</b>\n
    <b><u>Account Name:</u> STANDARD BANK OF SOUTH AFRICA</b>\n
    <b><u>Beneficiary:</u> African Development Bank</b>`;

  return (
    <div
      id="search-main"
      className="overlay-modal w-screen flex justify-center items-center h-screen z-50 fixed top-0 p-5"
    >
      <div className="w-[511px] rounded-md">
        <div className="bg-white py-[23px] pt-[10px] px-[26px] cursor-pointer min-h-[200px] max-h-[500px] overflow-scroll">
          <div className="flex justify-between items-center mb-3  border-b-2">
            <div>
              <h2 className="font-sBold uppercase ">
                {t("Thank you for supporting APNODE")}
              </h2>
            </div>
            <Close onClick={() => setShowPayment()} className="w-6" />
          </div>
          <p className="text-xs font-sLight">
            {t(
              "Thank you for supporting APNODE and its ongoing work to advance the uptake and utilization of evaluation to improve policy and decision making. You can donate via wire transfer using any of the following OPTIONS and associated Banks."
            )}
          </p>
          <br />
          <p className="text-xs mb-4 font-sLight">
            {t(
              "Please make checks payable to the African Development Bank for onward credit to APNODE."
            )}
          </p>
          <div className="grid lg:grid-cols-[25%_50%_25%] grid-cols-1 ">
            <div className="flex items-center mb-4">
              <input
                value="dollars"
                type="radio"
                name="payment"
                checked={selected === "dollars"}
                onChange={(e) => setSelected(e.target.value)}
                className="w-5 h-5  radio-item mr-2"
              />
              <label class="block mb-2 text-sm font-sLight text-[#555555] drop">
                {t("US Dollars")}
              </label>
            </div>
            <div className="flex items-center lg:justify-center  mb-4">
              <input
                value="rand"
                type="radio"
                name="payment"
                checked={selected === "rand"}
                onChange={(e) => setSelected(e.target.value)}
                className="w-5 h-5  radio-item mr-2"
              />
              <label class="block mb-2 text-sm font-sLight text-[#555555] drop">
                {t("South African Rand")}
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                value="cfa"
                type="radio"
                name="payment"
                checked={selected === "cfa"}
                onChange={(e) => setSelected(e.target.value)}
                className="w-5 h-5  radio-item mr-2"
              />
              <label class="block mb-2 text-sm font-sLight text-[#555555] drop">
                {t("CFA")}
              </label>
            </div>
          </div>

          <div className="">
            {selected === "dollars"
              ? dollars.split("\n").map((ele, i) => (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: ele,
                    }}
                    className="font-sLight text-base"
                  ></p>
                ))
              : null}
          </div>

          <div className="">
            {selected === "rand"
              ? rand.split("\n").map((ele, i) => (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: ele,
                    }}
                    className="font-sLight text-base"
                  ></p>
                ))
              : null}
          </div>

          <div className="">
            {selected === "cfa" ? <img src={cfa} /> : null}
          </div>
          <span className="text-xs mb-2 mx-auto text-center mt-6 font-sMedium block italic">
            {t("Kindly indicate APNODE as your subject of transfer.")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
