import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CountryModal from "./CountryModal";
import Footer from "./Footer";
import Menu from "./Menu";
import Navbar from "./Navbar";
import SearchMain from "./SearchMain";

const PageWrapper = ({ children }) => {
  let menuBtn;

  const lang = window.localStorage.getItem("apnode_lang");
  const [chapters, setChapters] = useState([]);
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [institutionalMembers, setInstitutionalMembers] = useState([]);
  const [parliaments, setParliaments] = useState([]);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [content, setContent] = useState("");

  const location = useLocation();
  var element;

  if (location.hash) {
    element = document.querySelector(location.hash);
  }

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        top: 100,
      });
    }
  }, [element]);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/institutional-members/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setInstitutionalMembers(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/parliament-members/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setParliaments(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/national-chapters/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setChapters(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const truncate = (str) => {
    return str.length > 300 ? str.substring(0, 295) + "..." : str;
  };

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  const handleCountryShow = (country, content) => {
    setShowCountryModal(true);
    setSelectedCountry(country);
    setContent(content);
  };

  return (
    <div>
      {show && <Menu show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      {showCountryModal && (
        <CountryModal
          country={selectedCountry}
          setShowCountryModal={() => setShowCountryModal(false)}
          content={content}
        />
      )}
      <Navbar
        setShowSearch={() => setShowSearch(false)}
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div className=" xl:w-[1200px] lg:w-[950px] w-full mx-auto xl:px-0 px-5 pt-[0px]">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default PageWrapper;
