import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import { getMonth } from "../util";
import { ReactComponent as Previous } from "../../assets/svgs/Previous.svg";
import { ReactComponent as Next } from "../../assets/svgs/Next.svg";
import { useDispatch } from "react-redux";
import { setDate } from "../../redux/dateSlice";
// import { setDate } from "../../redux/dateSlice.js";
import 'dayjs/locale/fr'

export default function SmallCalendar() {

  const lang = window.localStorage.getItem("apnode_lang");

  if(lang === "fr"){
    dayjs.locale('fr') 

  }
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);

  const { monthIndex, setSmallCalendarMonth, setDaySelected, daySelected } =
    useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonthIdx(monthIndex);
  }, [monthIndex]);

  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
  }
  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
  }
  function getDayClass(day) {
    const format = "DD-MM-YY";
    const nowDay = dayjs().format(format);
    const currDay = day.format(format);
    const slcDay = daySelected && daySelected.format(format);
    if (nowDay === currDay) {
      return "bg-buttonColor rounded-full text-white";
    } else if (currDay === slcDay) {
      return "bg-[#f2b070] rounded-full text-white font-bold";
    } else {
      return "";
    }
  }
  return (
    <div className="capitalize">
      <header className="flex justify-between items-center">
        <p className="text-appPText font-sBold font-sm">
          {dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM YYYY")}
        </p>
        <div className="flex ">
          <Previous
            className="mr-[10px] cursor-pointer"
            onClick={handlePrevMonth}
          />
          <Next className="cursor-pointer" onClick={handleNextMonth} />
        </div>
      </header>
      <div className="grid grid-cols-7 grid-rows-6 mt-[35px]">
        {currentMonth[0].map((day, i) => (
          <span
            key={i}
            className="text-sm py-1 text-center date-cell font-sMedium"
          >
            {day.format("dd")}
          </span>
        ))}
        {currentMonth.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <button
                key={idx}
                onClick={() => {
                  setSmallCalendarMonth(currentMonthIdx);
                  setDaySelected(day);
                  dispatch(setDate(`${day.$y}-${day.$M + 1}-${day.$D}`));
                }}
                className={`py-1 w-full ${getDayClass(day)}`}
              >
                <span className="text-sm date-cell">{day.format("D")}</span>
              </button>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
