import axios from "axios";
import { ReactComponent as Plus } from "../assets/svgs/Plus.svg";
import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../assets/svgs/cancel.svg";
import { useTranslation } from "react-i18next";

const SearchModal = ({ setShowSearch }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(t("all"));
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [text, setText] = useState(t("No recent searches"));

  var cats = [
    {
      value: "all",
      text: t("All"),
    },
    {
      value: "news",
      text: t("News"),
    },
    {
      value: "events",
      text: t("Events"),
    },
    {
      value: "publications",
      text: t("Publications"),
    },
    {
      value: "official_documents",
      text: t("Official Documents"),
    },
    {
      value: "reports",
      text: t("Reports"),
    },
    {
      value: "videos",
      text: t("Videos"),
    },
  ];

  const body = document.getElementsByClassName("card-body");
  const signs = document.getElementsByClassName("card-sign");
  const close = document.getElementById("close");

  const lang = window.localStorage.getItem("apnode_lang");

  useEffect(() => {
    if (search === "") setResults([]);
    var config = {
      method: "get",
      url: `/v1/search/general/${selected}/${lang}/${search}/`,
    };
    axios(config)
      .then(function (response) {
        var res = response.data;
        console.log(res);
        setResults(Object.entries(res));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [selected, search]);

  const handleClick = (ele) => {
    var plus = new DOMParser().parseFromString(
      `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0238 7H8.73211V0H6.93449V7H0.642822V9H6.93449V16H8.73211V9H15.0238V7Z" fill="#666666"/>
        </svg>
        `,
      "application/xml"
    );
    if (body[ele].classList.contains("inactive")) {
      signs[ele].classList.add("rotate");
      signs[
        ele
      ].innerHTML = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0238 7H8.73211V0H6.93449V7H0.642822V9H6.93449V16H8.73211V9H15.0238V7Z" fill="#666666"/>
      </svg>
      `;
      body[ele].classList.remove("inactive");
      body[ele].style.paddingTop = "24px";
    } else {
      signs[ele].classList.remove("rotate");
      body[ele].classList.add("inactive");
      body[ele].style.padding = "0px";
    }
  };

  return (
    <div className="bg-white py-[23px] pt-[10px] px-[26px] cursor-pointer min-h-[200px] max-h-[700px] overflow-scroll">
      <div className="flex justify-between">
        <div></div>
        <Close onClick={() => setShowSearch()} className="w-6" />
      </div>

      <div className="flex gap-5">
        <div>
          <select
            onChange={(e) => setSelected(e.target.value)}
            className="bg-gray-50 border  border-formBorder   text-gray-900 text-sm rounded-lg  outline-none block w-full p-2.5"
          >
            {cats.map((ele, i) => (
              <option
                value={ele.value}
                selected={ele.value === "all" ? true : false}
              >
                {ele.text}
              </option>
            ))}
          </select>
        </div>
        <div className="relative z-0 mb-6 w-full group">
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            name="floating_email"
            placeholder={t("Search...")}
            id="floating_email"
            className="block py-2.5 px-0 w-full  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-buttonColor peer"
          />
        </div>
      </div>
      <div className="min-h-10 w-full min-w-fit">
        {results.map((ele, i) => (
          <div>
            <div
              key={i}
              onClick={() => handleClick(i)}
              className="bg-secondBg  pl-[14px] pr-[19px] py-[16px] rounded-lg mb-4 cursor-pointer  "
            >
              <div className="grid grid-cols-[9fr_1fr] gap-5 justify-between items-start">
                <h6 className="text-appText font-sMedium capitalize">
                  {t(ele[0])}
                </h6>
                <div className="ml-auto pt-2 flex items-center">
                  <span className="text-xs mr-2 bg-buttonColor w-5 h-5 flex justify-center items-center rounded-full text-white">
                    {ele[1]?.length}
                  </span>
                  <div className="card-sign flex items-center ">
                    <Plus className="cursor-pointer " />
                  </div>
                </div>
              </div>
              <ul className="text-[13px] card-body inactive  text-cardText font-sLight ">
                {ele[1].map((elem, j) => (
                  <li className="hover:bg-buttonColor py-3 px-2 rounded-sm hover:text-white">
                    <a
                      target={`${
                        ele[0] === "News" || ele[0] === "Events"
                          ? "_self"
                          : "_blank"
                      }`}
                      href={`
                      ${
                        ele[0] == "Official Documents" ||
                        ele[0] == "Publications" ||
                        ele[0] == "Reports" ||
                        ele[0] == "Videos"
                          ? `${elem.link ? elem.link : elem.document}`
                          : `/${ele[0].toLowerCase()}/${elem.slug}`
                      }
                    `}
                    >
                      {ele[0] == "Events" ? elem.name : elem.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
        {results.length === 0 && (
          <p className="text-center text-sm text-">{text}</p>
        )}
      </div>
    </div>
  );
};

export default SearchModal;
