import React from "react";
import { ReactComponent as Arrow } from "../assets/svgs/arrow-forward.svg";
import { t } from "i18next";
const EventCard = ({ event }) => {
  const convertTime = (data) => {
    const time = data.split(":");
    return `${time[0]}:${time[1]}`;
  };

  const lang = window.localStorage.getItem("apnode_lang");

  const convertDate = (data) => {
    let locale = lang === "en" ?  'en-EN': 'fr-FR'
    const date = new Date(data);
    return date.toLocaleDateString(locale, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' });
  };
  return (
    <a
      href={`/events/${event.slug}`}
      className="px-[15px] mx-auto py-[8px] block event-card-shadow md:max-w-[304px] w-full  mb-[18px]"
    >
      <div className="mb-[6px] justify-between flex items-center font-medium">
        <div className="flex items-center ">
          <span className="w-[7px] h-[7px] bg-buttonColor rounded-full block"></span>
          <span className="text-[10px] text-appText ml-[6px] capitalize">
            {convertDate(event.event_date)} {" - "}
            {convertDate(event.event_end_date)}
          </span>
        </div>
        <div className="text-[10px] text-appText ml-[6px]"></div>
      </div>
      <p className="font-raleway font-bold">{event.name}</p>
      <a
        href={`/events/${event.slug}`}
        className="text-buttonColor  text-[10px] flex mt-[4px] items-center justify-end font-roboto"
      >
        <span className="mr-[6px]">{t("Read More")}</span> <Arrow />
      </a>
    </a>
  );
};

export default EventCard;
