import React from "react";
import { ReactComponent as Pdf } from "../../assets/svgs/pdf.svg";
import { ReactComponent as Download } from "../../assets/svgs/download.svg";
const PdfCard = ({ data }) => {
  const truncate = (str) => {
    return str.length > 25 ? str.substring(0, 23) + "..." : str;
  };

  const convertDate = (data) => {
    const date = new Date(data);
    const res = `${date.toDateString().split(" ")[1]} ${
      date.toDateString().split(" ")[3]
    }`;
    return res;
  };
  return (
    <a
      href={data.document ? data.document : data.link}
      download
      target="_blank"
      className="h-[87px] grid grid-cols-[85%_15%] download-shadow"
    >
      <div className="py-[18px] flex  px-3  text-sm">
        <div className="flex items-center ">
          {data.document ? (
            <img className="w-[38px] h-[47px]" src={data.thumbnail} />
          ) : (
            <Pdf />
          )}
          <div className="ml-[15px]">
            <p className="font-sMedium text-sm break-all">{data.title}</p>
            <p className="text-xs text-buttonColor font-sLight">
              {convertDate(data.date)}
            </p>
          </div>
        </div>
      </div>

      <a className="bg-navbarBg flex justify-center items-center cursor-pointer">
        <Download />
      </a>
    </a>
  );
};

export default PdfCard;
