import { createSlice } from "@reduxjs/toolkit";

var date = new Date();
date = date.toLocaleDateString().split("/");
var newDate = [date[2], date[0], date[1]].join("-");

const initialState = {
  eventDate: newDate,
};

export const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.eventDate = action.payload;
    },
  },
});

export const { setDate } = dateSlice.actions;

export default dateSlice.reducer;
