import React from "react";

import { t } from "i18next";

const SupportUs = () => {
  return (
    <div className="py-[70px] mt-10 bg-appBlack">
      <div className="lg:w-[900px] md:w-[700px] w-full block  mx-auto text-center items-center gap-[60px] px-5">
        <div>
          <h3 className="font-sm lg:text-[38px] text-[25px] uppercase   font-sBold text-white mb-[10px]">
            {t("Support Us")}
          </h3>
          <p className="text-white  lg:text-base text-[14px]">
            {t("Thank you for supporting APNODE")}
          </p>
        </div>
        <div className="w-full">
          <a
            href="/support-us"
            className="border mx-auto border-buttonColor mt-5 block px-[30px] py-[9px] w-fit text-white lg:text-base text-[14px]"
          >
            {t("Support Us")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SupportUs;
