import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import form from "../assets/images/form.jpeg";
import mem_req from "../assets/images/mem_req.jpg";
import Footer from "../components/Footer";
import { t } from "i18next";
import axios from "axios";
import { BallSpinner } from "react-spinners-kit";
import Menu from "../components/Menu";
import SearchMain from "../components/SearchMain";

const MembershipForm = () => {
  const [first_name, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [nationality, setNationality] = useState("");
  const [position, setPosition] = useState("");
  const [institution, setInstitution] = useState();
  const [official_mailing_address, setOfficialMailingAddress] = useState("");
  const [personal_mailing_address, setPersonalMailingAddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [cell_phone, setCellPhone] = useState("");
  const [email_1, setEmail1] = useState("");
  const [email_2, setEmail2] = useState("");
  const [category, setCategory] = useState("");
  const [marketing_status, setMarketingStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
    var data = JSON.stringify({
      first_name,
      surname,
      nationality,
      position,
      institution,
      official_mailing_address,
      personal_mailing_address,
      telephone,
      cell_phone,
      email_1,
      email_2,
      category,
      marketing_status,
    });

    console.log(JSON.parse(data))

    var config = {
      method: "post",
      url: "/v1/apnode/general/membership/",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        setLoading(false);
        setFirstName("");
        setSurname("");
        setNationality("");
        setPosition("");
        setInstitution("");
        setOfficialMailingAddress("");
        setPersonalMailingAddress("");
        setTelephone("");
        setCellPhone("");
        setEmail1("");
        setEmail2("");
        setCategory("");
        setMarketingStatus("");
        setLoading("");
        setMessage(response);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      })
      .catch(function (error) {
        setLoading(false);
        setMessage("Submission Failed");
        setTimeout(() => {
          setMessage("");
        }, 3000);
        console.log(error);
      });
  };

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  useEffect(() => {
    if (
      first_name == "" ||
      surname == "" ||
      nationality == "" ||
      position == "" ||
      institution == "" ||
      official_mailing_address == "" ||
      personal_mailing_address == "" ||
      telephone == "" ||
      cell_phone == "" ||
      email_1 == "" ||
      category == ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    first_name,
    surname,
    nationality,
    position,
    institution,
    official_mailing_address,
    personal_mailing_address,
    telephone,
    cell_phone,
    email_1,
    category,
  ]);

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  return (
    <div className="bg-white">
      {show && <Menu color="white" show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        color="white"
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div>
        <img className="mx-auto w-screen" src={mem_req} />
      </div>
      <div className="lg:w-[950px] w-full mx-auto  bg-white pt-[20px] px-4">
        <form className="mt-[60px] lg:w-[816px]  w-full mx-auto">
          <h1 className="text-center  mb-[10px]  text-4xl font-sSemiBold">
            {t("Membership Request Form")}
          </h1>
          <p className=" mb-[50px] ">
            {t(
              "Membership becomes official after approval and payment of the stipulated membership fee. Approvals of applications for Partner status is subject to a review and decision by the Annual General Meeting (AGM) of APNODE."
            )}
          </p>
          <div className="grid md:grid-cols-2 md:gap-6 mb-[10px]">
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                {t("First Name")}{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                className=" border text-sm font-sLight  border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder={t("First Name")}
              />
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                {t("Last Name")}{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                type="text"
                className=" border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder={t("Last Name")}
              />
            </div>
          </div>
          <div className="mb-[25px]">
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                {t("Nationality")}{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
                type="text"
                className=" border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder={t("Nationality")}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-6 mb-[10px]">
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                {t("Present Position/Title")}{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                value={position}
                type="text"
                onChange={(e) => setPosition(e.target.value)}
                className=" border text-sm font-sLight  border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder={t("Present Position/Title")}
              />
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                {t("Organisation/Institution")}{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                value={institution}
                type="text"
                onChange={(e) => setInstitution(e.target.value)}
                className=" border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder={t("Organisation/Institution")}
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6 mb-[10px]">
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                {t("Official Mailing Address")}{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                value={official_mailing_address}
                type="text"
                onChange={(e) => setOfficialMailingAddress(e.target.value)}
                className=" border text-sm font-sLight  border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder={t("Official Mailing Address")}
              />
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                {t("Personal Mailing Address")}{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                value={personal_mailing_address}
                type="text"
                onChange={(e) => setPersonalMailingAddress(e.target.value)}
                className=" border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder={t("Personal Mailing Address")}
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6 mb-[10px]">
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                {t("Telephone")}{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                type="tel"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
                className=" border text-sm font-sLight  border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder={t("Telephone")}
              />
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                {t("Cell Phone")}{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                type="tel"
                value={cell_phone}
                onChange={(e) => setCellPhone(e.target.value)}
                className=" border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder={t("Cell Phone")}
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6 mb-[10px]">
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                E-mail 1{" "}
                <span className="text-red-500 text-xs">*({t("required")})</span>
              </label>
              <input
                value={email_1}
                type="email"
                onChange={(e) => setEmail1(e.target.value)}
                className=" border text-sm font-sLight  border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder="E-mail 1"
              />
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <label className="block mb-[7px] text-sm font-sMedium text-appText capitalize">
                E-mail 2
              </label>
              <input
                value={email_2}
                type="email"
                onChange={(e) => setEmail2(e.target.value)}
                className=" border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                placeholder="E-mail 2"
              />
            </div>
          </div>

          <p className="font-sSemiBold text-[16px] mb-[30px]">
            {t(
              "I/We would like to become a member of APNODE in the following category (please tick your choice):"
            )}{" "}
            <span className="text-red-500 text-xs">*({t("required")})</span>
          </p>
          <div>
            <div className="grid grid-cols-[2fr_1fr_4fr] mb-[20px]">
              <label className="text-appText text-[14px]">
                {t("FULL MEMBER")}
              </label>
              <input
                type="radio"
                name="member"
                value="full member"
                checked={category === "full member"}
                onChange={(e) => setCategory(e.target.value)}
                className="w-5 h-5  radio-item mr-2"
              />
              <p className="text-memText text-[14px]">
                {t("Open to serving African parliamentarians")}
              </p>
            </div>
            <div className="grid grid-cols-[2fr_1fr_4fr] mb-[20px]">
              <label className="text-appText text-[14px]">
                {t("AFFILIATE MEMBER")}
              </label>
              <input
                type="radio"
                value="affiliate member"
                name="member"
                checked={category === "affiliate member"}
                onChange={(e) => setCategory(e.target.value)}
                className="w-5 h-5  radio-item mr-2"
              />
              <p className="text-memText text-[14px]">
                {t("Open to serving non-African parliamentarians")}
              </p>
            </div>
            <div className="grid grid-cols-[2fr_1fr_4fr] mb-[20px]">
              <label className="text-appText text-[14px]">
                {t("ASSOCIATE MEMBER")}
              </label>
              <input
                type="radio"
                name="member"
                value="associative member"
                checked={category === "associative member"}
                onChange={(e) => setCategory(e.target.value)}
                className="w-5 h-5  radio-item mr-2"
              />
              <p className="text-memText text-[14px]">
                {t(
                  "Open to former African and non-African parliamentarians, non-parliamentarian individuals, institutions and organizations"
                )}
              </p>
            </div>
            <div className="grid grid-cols-[2fr_1fr_4fr] mb-[20px]">
              <label className="text-appText text-[14px]">
                {t("INSTITUTIONAL MEMBER")}
              </label>
              <input
                type="radio"
                name="member"
                value="institutional member"
                checked={category === "institutional member"}
                onChange={(e) => setCategory(e.target.value)}
                className="w-5 h-5  radio-item mr-2"
              />
              <p className="text-memText text-[14px]">
                {t(
                  "Open to African National Parliaments and Regional Parliaments"
                )}
              </p>
            </div>
            <div className="grid grid-cols-[2fr_1fr_4fr] mb-[20px]">
              <label className="text-appText text-[14px]">{t("PARTNER")}</label>
              <input
                type="radio"
                name="member"
                value="partner"
                checked={category === "partner"}
                onChange={(e) => setCategory(e.target.value)}
                className="w-5 h-5  radio-item mr-2 text-buttonColor"
              />
              <p className="text-memText text-[14px]">
                {t(
                  "Upon approval by APNODE, offered to organizations, institutions, companies, individuals and NGOs who are of value or of keen interest to the Network."
                )}
              </p>
            </div>
          </div>
          <p className="font-sSemiBold text-[16px] mt-[30px]">
            {t(
              "I would like to receive electronic copies of APNODE communications and knowledge products"
            )}
          </p>
          <div className="mt-[30px] mb-[10px] flex items-center">
            <label className="text-appText text-[14px] mr-[10px]">
              {t("YES")}
            </label>
            <input
              type="radio"
              name="ack"
              value="true"
              checked={marketing_status === "true"}
              onChange={(e) => setMarketingStatus(e.target.value)}
              className="w-5 h-5  radio-item mr-2 rounded-none"
            />
          </div>
          <div className="flex items-center">
            <label className="text-appText text-[14px] mr-[14.5px]">
              {t("NO")}
            </label>
            <input
              onChange={(e) => setMarketingStatus(e.target.value)}
              type="radio"
              name="ack"
              value="false"
              checked={marketing_status === "false"}
              className="w-5 h-5  mr-2"
            />
          </div>
          <div className=" mt-[44px] mb-[100px] flex items-center ">
            <button
              disabled={disabled}
              onClick={submit}
              className={` bg-buttonColor disabled:opacity-50 ${
                loading
                  ? "opacity-60 cursor-not-allowed"
                  : "opacity-100 cursor-pointer"
              }   text-white px-[30px] py-[9px] text-[17px]  mr-5`}
            >
              {loading ? <BallSpinner color="#fff" /> : t("Submit Application")}
            </button>
            <p
              className={`text-sm  ${
                message.status == 201 ? "text-green-600" : "text-red-600"
              }  `}
            >
              {message.data}
            </p>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default MembershipForm;
