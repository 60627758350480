import React, { useState } from "react";
import team from "../assets/images/team-profile.png";
const TeamCard = ({ data }) => {
  const [show, toggle] = useState(false);
  return (
    <div
      // onMouseEnter={() => toggle(!show)}
      // onMouseLeave={() => toggle(!show)}
      className={`bg-navbarBg item relative max-w-[300px] event-card-shadow mb-10 mx-auto overflow-y-scroll transition-all ${
        show ? "bg-overlay" : ""
      } `}
    >
      {/* hover to review team info */}
      {show && (
        <div
          className={`absolute z-10 w-full h-full transition-all   ${
            show ? "bg-overlay" : ""
          } `}
        >
          <p className="text-white text-sm font-sThin p-3">
            {data.description}
          </p>
          <div className="bg-navbarBg p-3 font-sLight">
            <h6 className="text-buttonColor text-[18px] font-sMedium">
              {data.name}
            </h6>

            <p className="text-sm mb-2">
              <span className="text-buttonColor">
                {data.apnode_designation}
              </span>
            </p>
            <p className="text-sm mb-2">
              <span className="text-buttonColor">
                {data.national_affiliation}
              </span>
            </p>
            <div className="flex">
              <a href={data.linkedin}>
                <span className="w-[35px] font-sSemiBold flex items-center justify-center mr-2 h-[35px] border-[1.5px] border-buttonColor p-[10px] rounded-full text-sm text-buttonColor">
                  Li
                </span>
              </a>
              <a href={data.twitter}>
                <span className="w-[35px] font-sSemiBold flex items-center justify-center h-[35px] border-[1.5px] border-buttonColor p-[10px] rounded-full text-sm text-buttonColor">
                  Tw
                </span>
              </a>
            </div>
          </div>
        </div>
      )}
      <img className="h-[230px]  w-[296px]" src={data.image} />
      <div className="px-[16px] py-[17px]">
        <h6 className="text-buttonColor text-xl font-sMedium">{data.name}</h6>
        <p className="text-sm text-appPText font-sMedium">{data.apnode_designation}</p>
        <p className="text-sm mb-2">
          <span className="text-black">{data.national_affiliation}</span>
        </p>
      </div>
    </div>
  );
};

export default TeamCard;
