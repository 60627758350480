import React, { useState } from "react";
import { ReactComponent as Flag } from "../assets/svgs/flag.svg";
import { ReactComponent as Arrow } from "../assets/svgs/chevron-arrow-down.svg";
import { ReactComponent as Next } from "../assets/svgs/sub-right.svg";
import { ReactComponent as Search } from "../assets/svgs/search.svg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo.png";
import french from "../assets/svgs/french.svg";
const Navbar = ({ color, toggle, handleShow, showMenu, handleShowSearch }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [showEvents, setShowEvents] = useState(false);
  const [lang, setLang] = useState(window.localStorage.getItem("apnode_lang"));

  const changeLang = (e) => {
    window.location.reload();
    i18n.changeLanguage(e);
    window.localStorage.setItem("apnode_lang", e);
    setLang(e);
  };

  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-100px";
    }
    prevScrollpos = currentScrollPos;
  };

  return (
    <nav
      id="navbar"
      className={`w-full sticky top-0 py-3 z-10  ${
        color === "white" ? "bg-white" : "bg-navbarBg"
      }
      

      `}
    >
      {/* grid-cols-[25%_75%] */}
      <div className="xl:w-[1200px] lg:w-[950px] md:w-full xl:grid-cols-[2fr_6fr_3fr]  xl:grid flex  w-full mx-auto justify-between items-center xl:px-0 px-3 py-1 ">
        <a className={`${showMenu ? "invisible" : "visible"}`} href="/">
          <img src={logo} className="lg:w-[100px] w-[75px] " />
        </a>
        <ul className=" justify-evenly xl:flex hidden ">
          <li className="font-normal flex items-center">
            <a href="/" className="cursor-pointer">
              {t("Home")}
            </a>
            <Arrow
              onClick={() => {
                setShowAboutUs(false);
                setShow(!show);
                setShowResources(false);
              }}
              className="ml-3 cursor-pointer"
            />

            {show ? (
              <div className="bg-white w-[300px] border-b-[4px] border-b-[#FFE1C5] absolute transition-all top-[67px]">
                <ul className="pt-[11px]">
                  <li
                    className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                  ${
                    location.pathname.match("about-us") ? "bg-buttonColor" : ""
                  }            
                  `}
                  >
                    <a
                      className="w- flex items-center justify-between"
                      href="/about-us"
                    >
                      <span className="uppercase">{t("About Us")}</span>
                      <Next />
                    </a>
                  </li>
                  <li
                    className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all 
                      ${
                        location.pathname.match("team") ? "bg-buttonColor" : ""
                      } 
                  `}
                  >
                    <a
                      className="w- flex items-center justify-between "
                      href="/team"
                    >
                      <span className="uppercase">{t("Executive")}</span>
                      <Next />
                    </a>
                  </li>

                  {/* <li
                    className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all 
                      ${
                        location.pathname.match("team#secretariat")
                          ? "bg-buttonColor"
                          : ""
                      } 
                  `}
                  >
                    <a
                      className="w- flex items-center justify-between "
                      href="/team#secretariat"
                    >
                      <span className="uppercase">{t("Secretariat")}</span>
                      <Next />
                    </a>
                  </li> */}

                  <li
                    className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                      ${
                        location.pathname.match("history")
                          ? "bg-buttonColor"
                          : ""
                      } 
                  
                  `}
                  >
                    <a
                      className="w- flex items-center justify-between "
                      href="/history"
                    >
                      <span className="uppercase">{t("Milestones")}</span>
                      <Next />
                    </a>
                  </li>
                </ul>
              </div>
            ) : null}
          </li>
          <li
            className={`hover:border-b-buttonColor flex items-center  hover:border-b-[3px] transition-all${
              location.pathname.match("membership")
                ? "text-buttonColor border-b-[3px] font-sBold border-b-buttonColor "
                : ""
            }            
          
          `}
          >
            <a href="/membership">{t("Membership")}</a>
            <Arrow
              onClick={() => {
                setShowAboutUs(!showAboutUs);
                setShow(false);
                setShowResources(false);
              }}
              className="ml-3 cursor-pointer"
            />
          </li>
          {showAboutUs ? (
            <div className="bg-white w-[300px] border-b-[4px] border-b-[#FFE1C5] absolute transition-all top-[67px]">
              <ul className="pt-[11px]">
                <li
                  style={{ fontWeight: "normal !important" }}
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                  ${
                    location.pathname.match("about-us") ? "bg-buttonColor" : ""
                  }            
                  `}
                >
                  <a
                    onClick={() => {
                      setShowAboutUs(false);
                    }}
                    className="w- flex items-center justify-between"
                    href="/membership#join"
                  >
                    <span className="uppercase">
                      {t("Who can join APNODE?")}
                    </span>
                  </a>
                </li>
                <li
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all 
                  `}
                >
                  <a
                    onClick={() => {
                      setShowAboutUs(false);
                    }}
                    className="w- flex items-center font-normal justify-between "
                    href="/membership#why"
                  >
                    <span className="uppercase"> {t("Why Join APNODE?")}</span>
                  </a>
                </li>

                <li
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                  
                  `}
                >
                  <a
                    onClick={() => {
                      setShowAboutUs(false);
                    }}
                    className="w- flex items-center justify-between "
                    href="/membership#chapters"
                  >
                    <span className="uppercase">
                      {" "}
                      {t("APNODE NATIONAL CHAPTERS")}
                    </span>
                  </a>
                </li>

                <li
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                  
                  `}
                >
                  <a
                    onClick={() => {
                      setShowAboutUs(false);
                    }}
                    className="w- flex items-center justify-between "
                    href="/membership#steps"
                  >
                    <span className="uppercase">
                      {" "}
                      {t("Steps to setting up an APNODE National Chapter")}
                    </span>
                  </a>
                </li>
                <li
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                  
                  `}
                >
                  <a
                    onClick={() => {
                      setShowAboutUs(false);
                    }}
                    className="w- flex items-center justify-between "
                    href="/membership#parliament"
                  >
                    <span className="uppercase">
                      {t(
                        "Institutional Members & Parliaments with APNODE Members"
                      )}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
          <li
            className={`hover:border-b-buttonColor  flex items-center hover:border-b-[3px]  transition-all   ${
              location.pathname.match("resources")
                ? "text-buttonColor border-b-[3px] font-sBold border-b-buttonColor "
                : ""
            }            
          `}
          >
            <a href="/resources">{t("Resources")}</a>
            <Arrow
              onClick={() => {
                setShowAboutUs(false);
                setShow(false);
                setShowResources(!showResources);
              }}
              className="ml-3 cursor-pointer"
            />
          </li>
          {showResources ? (
            <div className="bg-white w-[300px] border-b-[4px] border-b-[#FFE1C5] absolute transition-all top-[67px]">
              <ul className="pt-[11px]">
                <li
                  style={{ fontWeight: "normal !important" }}
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                  `}
                >
                  <a
                    onClick={() => {
                      setShowResources(false);
                    }}
                    className="w- flex items-center justify-between"
                    href="/resources#official"
                  >
                    <span className="uppercase">{t("Official Documents")}</span>
                  </a>
                </li>
                <li
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all 
                  `}
                >
                  <a
                    onClick={() => {
                      setShowResources(false);
                    }}
                    className="w- flex items-center font-normal justify-between "
                    href="/resources#reports"
                  >
                    <span className="uppercase"> {t("Reports")}</span>
                  </a>
                </li>

                <li
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                  `}
                >
                  <a
                    onClick={() => {
                      setShowResources(false);
                    }}
                    className="w- flex items-center justify-between "
                    href="/resources#publications"
                  >
                    <span className="uppercase"> {t("Publications")}</span>
                  </a>
                </li>

                <li
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                      ${
                        location.pathname.match("history")
                          ? "bg-buttonColor"
                          : ""
                      } 
                  
                  `}
                >
                  <a
                    onClick={() => {
                      setShowAboutUs(false);
                    }}
                    className="w- flex items-center justify-between "
                    href="/resources#videos"
                  >
                    <span className="uppercase"> {t("Videos")}</span>
                  </a>
                </li>

                <li
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                      ${
                        location.pathname.match("gallery")
                          ? "bg-buttonColor"
                          : ""
                      } 
                  
                  `}
                >
                  <a
                    onClick={() => {
                      setShowAboutUs(false);
                    }}
                    className="w- flex items-center justify-between "
                    href="/resources/gallery"
                  >
                    <span className="uppercase"> {t("Gallery")}</span>
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
          <li
            className={`hover:border-b-buttonColor  hover:border-b-[3px]  transition-all   ${
              location.pathname.match("news")
                ? "text-buttonColor border-b-[3px] font-sBold border-b-buttonColor "
                : ""
            }            
          `}
          >
            <a href="/news">{t("News")}</a>
          </li>
          <li
            className={`hover:border-b-buttonColor flex items-center hover:border-b-[3px] transition-all ${
              location.pathname.match("events")
                ? "text-buttonColor border-b-[3px] font-sBold border-b-buttonColor "
                : ""
            }            
        
        `}
          >
            <a href="/events">{t("Events")}</a>
            <Arrow
              onClick={() => {
                setShowAboutUs(false);
                setShow(false);
                setShowResources(false);
                setShowEvents(!showEvents);
              }}
              className="ml-3 cursor-pointer"
            />
          </li>

          {showEvents ? (
            <div className="bg-white w-[300px] border-b-[4px] border-b-[#FFE1C5] absolute transition-all top-[67px]">
              <ul className="pt-[11px]">
                <li
                  style={{ fontWeight: "normal !important" }}
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all
                  `}
                >
                  <a
                    onClick={() => {
                      setShowEvents(false);
                    }}
                    className="w- flex items-center justify-between"
                    href="/events#upcoming"
                  >
                    <span className="uppercase">{t("Upcoming Events")}</span>
                  </a>
                </li>
                <li
                  className={`text-black px-[16px] py-[8px] text-[13px] hover:bg-buttonColor transition-all 
                  `}
                >
                  <a
                    onClick={() => {
                      setShowEvents(false);
                    }}
                    className="w- flex items-center font-normal justify-between "
                    href="/events#past"
                  >
                    <span className="uppercase"> {t("Past Events")}</span>
                  </a>
                </li>
              </ul>
            </div>
          ) : null}

          <li
            className={`hover:border-b-buttonColor  hover:border-b-[3px] transition-all ${
              location.pathname.match("contact-us")
                ? "text-buttonColor border-b-[3px] font-sBold border-b-buttonColor "
                : ""
            }            
        `}
          >
            <a href="/contact-us">{t("Contact Us")}</a>
          </li>
        </ul>

        <div className="xl:flex hidden items-center  justify-end ">
          <Search
            fill="#EC892A"
            stroke="#EC892A"
            onClick={() => handleShowSearch(!showSearch)}
            className="mr-5 w-[24px] cursor-pointer"
          />

          <div
            onClick={() => setShowLang(!showLang)}
            className="flex items-center justify-between cursor-pointer mr-4 "
          >
            <span className="mr-2">
              {lang === "fr" ? (
                <div className="flex items-center">
                  <img src={french} className="mr-2" />
                  <span onClick={() => setShowLang(!showLang)} className="mr-2">
                    Fr
                  </span>
                </div>
              ) : (
                <div className="flex items-center">
                  <Flag className="mr-2" />
                  <span onClick={() => setShowLang(!showLang)} className="mr-2">
                    Eng
                  </span>
                </div>
              )}
            </span>
            <Arrow />
            {showLang && (
              <div className="w-[150px] bg-white absolute top-[67px]  translate-x-[-50px]">
                <div
                  onClick={() => changeLang("en")}
                  className="flex items-center justify-center cursor-pointer py-[14px] mx-auto border-b border-b-[#FFFCF9] hover:bg-gray-100"
                >
                  <Flag className="mr-2" />
                  <span className="mr-2">English</span>
                </div>
                <div
                  onClick={() => changeLang("fr")}
                  className="flex items-center justify-center cursor-pointer  py-[14px] mx-auto hover:bg-gray-100"
                >
                  <img src={french} className="mr-2" />
                  <span className="mr-2">French</span>
                </div>
              </div>
            )}
          </div>
          <a
            href="/support-us"
            className="bg-buttonColor xl:block hidden text-white px-[30px] py-[9px] text-[16px]"
          >
            {t("Support Us")}
          </a>
        </div>
        <div className="flex items-center">
          <Search
            fill="#EC892A"
            stroke="#EC892A"
            onClick={() => handleShowSearch(!showSearch)}
            className="mr-5 w-[24px] cursor-pointer xl:hidden block"
          />
          <div
            onClick={() => handleShow(!showMenu)}
            className="menu-btn xl:hidden flex h-0"
          >
            <div className="menu-btn__burger"></div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
