import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import PageWrapper from "../components/PageWrapper";
import { ReactComponent as Back } from "../assets/svgs/back.svg";
import { t } from "i18next";
import Slideshow from "../components/Resources/Slideshow";
import PhotoSlideShow from "../components/Resources/PhotoSlideShow";

const GalleryDetails = () => {
  let { slug } = useParams();
  const [mainImage, setMainImage] = useState({});
  const [pictures, setPictures] = useState([]);
  const [currentImage, setCurrentImage] = useState({});
  const mason = document.getElementById("mason-gallery");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`https://api.apnode.org/api/v1/gallery/albums/${slug}`)
      .then((res) => {
        console.log(res.data);
        setMainImage({
          image: res.data.image,
          description: res.data.description,
        });
        setPictures(res.data.pictures);
        console.log(res.data.pictures);
      });
  }, []);

  return (
    <PageWrapper>
      <div className="mt-10 min-h-screen">
        <div
          onClick={() => navigate("/resources/gallery")}
          className="flex xl:w-[1200px] items-center w-fit cursor-pointer mb-10"
        >
          <Back stroke="black" className="mr-[15px]" />
          <span className=" font-sSemiBold text-lg">{t("Back to albums")}</span>
        </div>
        <div className="mb-2 ">
          {pictures.length > 0 ? (
            <PhotoSlideShow albums={pictures} />
          ) : pictures.length == 0 ? (
            <p>no pictures...</p>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        {/* <div className="mb-2 relative">
          <div className="w-full h-[400px] absolute bg-[rgba(0,0,0,0.5)] z-2"></div>
          <img
            className="w-full h-[400px] object-cover"
            src={mainImage.image}
            alt={mainImage.description}
          />
          <p className="absolute top-[50%] text-white text-center left-[50%] translate-x-[-50%] translate-y-[-50%] font-bold text-4xl">
            {mainImage.description}
          </p>
        </div> */}
        <div className="grid grid-cols-2 gap-2">
          {pictures.map((ele, i) => (
            <Link
              key={i}
              to={`photos`}
              state={{
                data: pictures,
                currentImage: ele,
              }}
            >
              <img
                key={i}
                className="w-full h-[400px] object-cover"
                src={ele.file}
              />
            </Link>
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};

export default GalleryDetails;
