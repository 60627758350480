import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { ReactComponent as Back } from "../assets/svgs/back.svg";
import article from "../assets/images/article.png";
import Footer from "../components/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { t } from "i18next";
import Menu from "../components/Menu";
import SearchMain from "../components/SearchMain";

const NewsArticle = () => {
  let { id } = useParams();
  const lang = window.localStorage.getItem("apnode_lang");
  const [article, setArticle] = useState();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  console.log(id)
  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/news/general/news/${lang}/${id}`,
    };
    axios(config)
      .then(function (response) {
        if (!response.data) {
          navigate("/news");
        }
        setArticle(response.data);
      })
      .catch(function (error) {
        navigate("/news");
        console.log(error);
      });
  }, []);

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  return (
    <div className="bg-white">
      {show && <Menu show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        handleShowSearch={handleShowSearch}
        showMenu={show}
        handleShow={handleShow}
      />{" "}
      <div className="xl:w-[1200px] w-full mx-auto  bg-white lg:pt-[20px] pt-[20px]">
        <div className="lg:mt-[56px]  lg:w-[898px] lg:px-0 px-4  mx-auto mt-[10px]">
          <div
            onClick={() => navigate(-1)}
            className="flex xl:w-[1200px] items-center w-fit cursor-pointer"
          >
            <Back stroke="black" className="mr-[15px]" />
            <span className=" font-sSemiBold text-xl">{t("Back")}</span>
          </div>
          <div className="mt-[40px] text-[11px] mb-[15px] flex">
            <p className="font-sSemiBold mr-[20px]">
              {article?.categories?.map((ele, i) => (
                <p className="font-sSemiBold mr-[20px]">{t(ele.name)}</p>
              ))}
            </p>
            <p className="text-formText ">
              {new Date(article?.published_date).toDateString()}
            </p>
          </div>
          <h2 className="text-2xl font-sSemiBold mb-[40px]">
            {article?.title}
          </h2>
          <img className="w-full" src={article?.image} />
          <p
            dangerouslySetInnerHTML={{
              __html: article?.description,
            }}
            className="text-appText mt-[40px] mb-[100px] px-4"
          ></p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsArticle;
