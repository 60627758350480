import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slideshow from "../components/Resources/Slideshow";
import { ReactComponent as Back } from "../assets/svgs/back.svg";
import { t } from "i18next";

const GalleryPhoto = () => {
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location.state);
  const all_photos = location.state.data;
  const index = all_photos.findIndex(
    (obj) => obj.id === location.state.currentImage.id
  );
  let temp = all_photos.splice(index, 1)[0];

  all_photos.unshift(temp);
  const [pictures, setPictures] = useState();
  const [currentImage, setCurrentImage] = useState({});

  useEffect(() => {
    const arrow_right = document.getElementsByClassName("owl-next");
    const arrow_left = document.getElementsByClassName("owl-prev");
    if (arrow_right) {
      arrow_right[0].childNodes[0].setAttribute("aria-label", "");
      arrow_right[0].childNodes[0].classList.add("custom-next");
      arrow_right[0].childNodes[0].innerHTML = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.4767 6.16664L6.00668 1.69664L7.18501 0.518311L13.6667 6.99998L7.18501 13.4816L6.00668 12.3033L10.4767 7.83331H0.333344V6.16664H10.4767Z" fill="#121212"/>
          </svg>
          `;
    }
    if (arrow_left) {
      arrow_left[0].childNodes[0].setAttribute("aria-label", "");
      arrow_left[0].childNodes[0].classList.add("custom-prev");
      arrow_left[0].childNodes[0].innerHTML = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.52398 6.16664H13.6673V7.83331H3.52398L7.99398 12.3033L6.81565 13.4816L0.333984 6.99998L6.81565 0.518311L7.99398 1.69664L3.52398 6.16664Z" fill="#121212"/>
          </svg>
          `;
    }
  });

  var photos = [];

  useEffect(() => {
    getPhotos();
  }, [pictures, currentImage]);

  function getPhotos() {
    document.body.style.overflowY = "hidden";
    setPictures(location.state.data);
    setCurrentImage(location.state.currentImage);
  }

  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 1,
    },
  };

  const options = {

    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    loop: true,
  };

  return (
    <div className="bg-[rgba(0,0,0,0.9)] h-full w-screen ">
      <div
        onClick={() => navigate(-1)}
        className="flex xl:w-[1200px] items-center w-fit cursor-pointer pt-10 pl-10"
      >
        <Back stroke="white" className="mr-[15px]" />
        <span className=" font-sSemiBold text-lg text- text-white">
          {t("Back to ")} {location.state.currentImage.album.name}
        </span>
      </div>
      <OwlCarousel
        className="owl-theme flex justify-center items-center"
        margin={24}
        nav
        responsive={responsive}
        {...options}
      >
        {location.state.data.length > 0 ? (
          <>
            {location.state.data.map((ele, i) => (
              <div className="h-screen flex justify-center items-center">
                <img src={ele.file} key={i} className="scale-[0.5] " />
              </div>
            ))}
          </>
        ) : (
          <p>loading...</p>
        )}
      </OwlCarousel>
    </div>
  );
};

export default GalleryPhoto;
