import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import event from "../assets/images/events.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import SearchMain from "../components/SearchMain";
import { t } from "i18next";

const MainEvent = () => {
  let { id } = useParams();
  const [event, setEvent] = useState();
  const lang = window.localStorage.getItem("apnode_lang");
  const [showSearch, setShowSearch] = useState(false);
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);

  let menuBtn;
  useEffect(() => {
    console.log("eeee");
    var config = {
      method: "get",
      url: `/v1/events/general/events/${lang}/${id}/`,
    };
    axios(config)
      .then(function (response) {
        console.log("=>", response.data);
        setEvent(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleShow = (data) => {
    if (menuBtn) {
      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        console.log("else");
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  const convertTime = (data) => {
    if (!data) {
      return "Not Specified!";
    }
    const time = data.split(":");
    return `${time[0]}:${time[1]}`;
  };

  const convertDate = (data) => {
    if (!data) {
      return "Not Specified!";
    }
    const date = new Date(data);
    return date.toDateString();
  };

  return (
    <div className="bg-white">
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />
      <div>
        <img className="mx-auto w-screen" src={event?.image} />
      </div>
      <div className="lg:w-[1300px] w-full mx-auto xl:grid xl:grid-cols-[750px_450px] block gap-[100px]  py-16 px-5">
        <div>
          <div className="mb-[40px]">
            <h3 className="text- text-2xl mb-[23px] font-sBold">
              {t("Description")}
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: event?.description,
              }}
              className="text-appText font-roboto "
            ></p>
          </div>
        </div>
        {event ? (
          <div>
            <h3 className="text- text-2xl  font-sBold mb-[5px]">
              {t("Event Name")}
            </h3>
            <p className="mb-[5px] text-sm text-appText  font-roboto pr-10 ">
              {event?.name}
            </p>
            <h3 className="text- text-2xl mb-[5px] mt-5 font-sBold">
              {t("Event Location")}
            </h3>
            <p className="mb-[5px] text-sm text-appText  font-roboto pr-10 ">
              {event?.location}
            </p>
            <h3 className="text- text-2xl  font-sBold mb-[5px] mt-5">
              {t("Date")}
            </h3>
            <p className="mb-[5px] text-sm text-appText  font-roboto ">
              {event?.event_date === event?.event_end_date ? (
                convertDate(event?.event_date)
              ) : (
                <>
                  {convertDate(event?.event_date)} {" - "}
                  {convertDate(event?.event_end_date)}
                </>
              )}
            </p>
            <h3 className="text- text-2xl  font-sBold mb-[5px] mt-5">
              {t("Time")}
            </h3>
            <p className="mb-[5px] text-sm text-appText  font-roboto ">
              {convertTime(event?.start_time)} - {convertTime(event?.end_time)}
            </p>

            <h3 className="text- text-2xl  font-sBold mb-[5px] mt-5">
              {t("Mode")}
            </h3>
            <p className="mb-[5px] text-sm text-appText  font-roboto ">
              {event.mode}
            </p>
            <h3 className="text- text-2xl  font-sBold mb-[5px] mt-5">
              {t("Conference Webiste")}
            </h3>
            {event.conference_website ? (
              <a
                href={event.conference_website}
                target="_blank"
                className="mb-[5px] text-sm text-appText  font-roboto "
              >
                {event.conference_website}
              </a>
            ) : (
              <p className="mb-[5px] text-sm text-appText  font-roboto ">
                {t("Conference does not have a webiste")}
              </p>
            )}
            {/* <h3 className="text- text-2xl  font-sBold mb-[5px] mt-5">
              Add To Calender
            </h3> */}
            <a
              href={event.calendar_file}
              className="border  border-buttonColor mt-10 block px-[30px] py-[9px] w-fit text-buttonColor lg:text-base text-[14px]"
            >
              {t("Add To Calender")}
            </a>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
};

export default MainEvent;
