import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";

const Slideshow = ({ albums }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const arrow_right = document.getElementsByClassName("owl-next");
    const arrow_left = document.getElementsByClassName("owl-prev");
    if (arrow_right) {
      arrow_right[0].childNodes[0].setAttribute("aria-label", "");
      arrow_right[0].childNodes[0].classList.add("custom-next");
      arrow_right[0].childNodes[0].innerHTML = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.4767 6.16664L6.00668 1.69664L7.18501 0.518311L13.6667 6.99998L7.18501 13.4816L6.00668 12.3033L10.4767 7.83331H0.333344V6.16664H10.4767Z" fill="#121212"/>
          </svg>
          `;
    }
    if (arrow_left) {
      arrow_left[0].childNodes[0].setAttribute("aria-label", "");
      arrow_left[0].childNodes[0].classList.add("custom-prev");
      arrow_left[0].childNodes[0].innerHTML = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.52398 6.16664H13.6673V7.83331H3.52398L7.99398 12.3033L6.81565 13.4816L0.333984 6.99998L6.81565 0.518311L7.99398 1.69664L3.52398 6.16664Z" fill="#121212"/>
          </svg>
          
          `;
    }
  }, []);

  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 1,
    },
  };

  const options = {
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    loop: true,
  };
  return (
    <div>
      <OwlCarousel
        className="owl-theme"
        margin={24}
        nav
        responsive={responsive}
        {...options}
      >
        {albums.map((ele, i) => (
          <div
            key={i}
            className="w-full h-[400px] bg-[rgba(0,0,0,0.5)] relative cursor-pointer"
            onClick={() => navigate(`/resources/gallery/${ele.slug}`)}
          >
            <div className="w-full h-[450px] bg-[rgba(0,0,0,0.5)] absolute flex justify-center items-center ">
              <span className="text-white font-sBold text-[40px]  ">
                {ele.name}
              </span>
            </div>
            <img src={ele.image} alt={ele.name} className="w-full h-fit" />
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Slideshow;
