import React from "react";

const VideoCard = ({ data, type }) => {
  const convertDate = (data) => {
    const date = new Date(data);
    const res = `${date.toDateString().split(" ")[1]} ${
      date.toDateString().split(" ")[3]
    }`;
    return res;
  };
  return (
    <div className="report-card   transition-all ">
      <iframe
        className=" w-full mb-1"
        height="194"
        src={data.link}
        allow="fullscreen;"
      ></iframe>
      {type === "see all" ? <p className="text-sm">{data.title}</p> : null}
      {type === "see all" ? <p className="text-sm text-buttonColor">{convertDate(data.date)}</p> : null}
    </div>
  );
};

export default VideoCard;
