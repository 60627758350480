import React from "react";
import quotes from "../assets/svgs/quotes.svg";
const SuccessCard = ({ data }) => {
  return (
    <a
    target="_blank"
      href={data.link}
      className="lg:max-w-[296px] sucesss-card  w-full  bg-appBlack relative transition-all border-b-4  border-buttonColor shadow-lg "
    >
      <img className="w-full h-[170px]  mb-4 object-cover" src={data.image} />
      {/* <h6 className="text-white font-sMedium mb-[10px]  text-2xl  px-6 ">
        {data.name}
      </h6> */}
      <p  className="text-white  px-2   font-sLight text-sm leading-[22.4px] py-3">
        {data.description}
      </p>
    </a>
  );
};

export default SuccessCard;
      // py-[41px]
