import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { ReactComponent as Minus } from "../assets/svgs/minus.svg";
import { ReactComponent as Close } from "../assets/svgs/Close.svg";
import { ReactComponent as Plus } from "../assets/svgs/Plus.svg";
import Footer from "../components/Footer";
import { t } from "i18next";

const Faq = () => {
  const [show, toggle] = useState(false);
  const [index, setIndex] = useState(0);

  const body = document.getElementsByClassName("card-body");
  const signs = document.getElementsByClassName("card-sign");
  const close = document.getElementById("close");

  const handle = (ele, i) => {
    if (!show) {
      console.log("show");
      setTimeout(() => {
        toggle(true);
        setIndex(i);
      }, 300);
      document.getElementById(ele).classList.add("rotate");
    } else {
      console.log("hide");

      setTimeout(() => {
        toggle(false);
        setIndex(0);
      }, 300);
      document.getElementById(ele).classList.add("rotate");
    }
  };

  const handleClick = (ele) => {
    var plus = new DOMParser().parseFromString(
      `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0238 7H8.73211V0H6.93449V7H0.642822V9H6.93449V16H8.73211V9H15.0238V7Z" fill="#666666"/>
        </svg>
        `,
      "application/xml"
    );
    console.log(plus.documentElement);
    if (body[ele].classList.contains("inactive")) {
      console.log(close);
      signs[ele].classList.add("rotate");
      signs[
        ele
      ].innerHTML = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0238 7H8.73211V0H6.93449V7H0.642822V9H6.93449V16H8.73211V9H15.0238V7Z" fill="#666666"/>
      </svg>
      `;
      body[ele].classList.remove("inactive");
      body[ele].style.paddingTop = "24px";
    } else {
      signs[ele].classList.remove("rotate");
      body[ele].classList.add("inactive");
      body[ele].style.padding = "0px";
    }
    
  };

  const col1 = [
    {
      question: "Can i attend APNODE events online?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
      question:
        "Developing Effective Inclusive Growth on the African Continent?",
      answer:
        "The African Parliamentarians' Network on Development Evaluation (APNODE) is a network of African parliamentarians and development partners promoting the use of evaluation in national decision making to support.",
    },
    {
      question:
        "Lorem Ipsum is simply dummy text of the printing and typesetting?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
      question: "How do i become a member?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
  ];

  const col2 = [
    {
      question:
        "Lorem Ipsum is simply dummy text of the printing and typesetting?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
      question: "Can i attend APNODE events online?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
      question:
        "Developing Effective Inclusive Growth on the African Continent?",
      answer:
        "The African Parliamentarians' Network on Development Evaluation (APNODE) is a network of African parliamentarians and development partners promoting the use of evaluation in national decision making to support.",
    },
    {
      question: "How do i become a member?",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
  ];
  return (
    <div className="bg-appBg">
      <Navbar />
      <div className="bg-navbarBg">
        <div className="max-w-[1300px] mx-auto pt-10">
          <h2 className="font-sBold text-2xl pb-[12px]">
            {t("Frequently Asked Questions")}
          </h2>
        </div>
      </div>
      <div className="sharp-bg mb-[100px]">
        <div className="max-w-[1300px] bg-white  shadow-md mx-auto pl-[80px] pr-[60px] py-[57px] grid grid-cols-[30%_70%]">
          <div>
            <ul className="text-appText font-sLight ">
              <li className="mb-[24px] text-buttonColor font-sSemiBold flex items-center">
                <Minus className="mr-2" /> {t("General")}
              </li>
              <li className="mb-[24px] pl-[27px]">{t("Resources")}</li>
              <li className="mb-[24px] pl-[27px]">{t("Events")}</li>
              <li className="mb-[24px] pl-[27px]">{t("Contact Us")}</li>
            </ul>
          </div>
          <div className=" grid grid-cols-2 gap-[51px]">
            <div>
              {col1.map((ele, i) => {
                return (
                  <div
                    onClick={() => handleClick(i)}
                    className="bg-secondBg pl-[14px] pr-[19px] py-[16px] rounded-lg mb-4 cursor-pointer  "
                  >
                    <div className="grid grid-cols-[9fr_1fr] gap-5 justify-between items-start">
                      <h6 className="text-appText font-sMedium">
                        {ele.question}
                      </h6>
                      <div className="ml-auto pt-2">
                        <div className="card-sign ">
                          <Plus className="cursor-pointer " />
                        </div>
                      </div>
                    </div>
                    <p className="text-[13px] card-body inactive  transition-all text-cardText font-sLight ">
                      {ele.answer}
                    </p>
                  </div>
                );
              })}
            </div>
            <div>
              {col2.map((ele, i) => {
                return (
                  <div
                    onClick={() => handleClick(col1.length + i)}
                    className="bg-secondBg  pl-[14px] pr-[19px] py-[16px] rounded-lg mb-4 cursor-pointer  "
                  >
                    <div className="grid grid-cols-[9fr_1fr] gap-5 justify-between items-start">
                      <h6 className="text-appText font-sMedium">
                        {ele.question}
                      </h6>
                      <div className="ml-auto pt-2">
                        <div className="card-sign ">
                          <Plus className="cursor-pointer " />
                        </div>
                      </div>
                    </div>
                    <p className="text-[13px] card-body inactive  text-cardText font-sLight ">
                      {ele.answer}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
