import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import aboutus from "../assets/images/aboutus-hero.jpg";
import building from "../assets/images/building.png";
import we from "../assets/images/we.jpg";
import o1 from "../assets/images/o1.jpg";
import o2 from "../assets/images/o2.jpg";
import o3 from "../assets/images/o3.jpg";
import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { ReactComponent as Prev } from "../assets/svgs/slide-prev.svg";
import { ReactComponent as Next } from "../assets/svgs/slide-next.svg";
import SuccessCard from "../components/SuccessCard";
import Footer from "../components/Footer";
import SupportUsComponent from "../components/SupportUs";
import m1 from "../assets/images/m1.jpg";
import m2 from "../assets/images/m2.jpg";
import m3 from "../assets/images/m3.jpg";
import Donation2 from "../components/Donations/Donation2";
import afrea from "../assets/images/afrea.jpg";
import eval4 from "../assets/images/eval4.png";
import evalpartners from "../assets/images/evalpartners.jpg";
import ioce from "../assets/images/ioce.png";
import gpfe from "../assets/images/gpfe.png";
import clear from "../assets/images/clear.jpg";
import rfe from "../assets/images/rfe.jpg";
import idev from "../assets/images/idev.png";
import twende from "../assets/images/twende.png";
import unicef from "../assets/images/unicef.png";
import unw from "../assets/images/unw.png";
import undp from "../assets/images/undp.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Menu from "../components/Menu";
import Interested from "../components/Interested";
import SearchMain from "../components/SearchMain";
import PaymentModal from "../components/PaymentModal";
import objsPic from "../assets/images/objs.jpg";

const SupportUs = () => {
  const { t } = useTranslation();
  const [move, setMove] = useState(0);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

  const lang = window.localStorage.getItem("apnode_lang");

  const objs = [
    {
      value: "01",
      text: t(
        "Sensitize national parliaments and parliamentarians about the importance of evaluation for oversight, policy and decision making"
      ),
    },
    {
      value: "02",
      text: t(
        "Enhance the capacity of parliamentarians to demand and utilize evaluation evidence in their parliamentary duties"
      ),
    },
    {
      value: "03",
      text: t(
        "Encourage parliaments to institutionalize evaluation to inform decision and policymaking"
      ),
    },
    {
      value: "04",
      text: t("Share experiences across Africa and beyond"),
    },
    {
      value: "05",
      text: t(
        "Support parliamentarians to ensure that country-level evaluations are responsive to the concerns of gender equality, vulnerable groups, and equitable development results"
      ),
    },
    {
      value: "06",
      text: t(
        "Assist MPs to embrace a culture of evaluation and evidence-based decision-making at all levels of government, and"
      ),
    },
    {
      value: "07",
      text: t(
        "Bridge the gap between parliamentarians (as users of evaluation evidence) and evaluators (as purveyors of impartial evidence)"
      ),
    },
  ];

  const logos = [
    {
      image: afrea,
      alt: "AfrEa",
      link: "https://afrea.org/",
    },
    {
      image: eval4,
      alt: "Eval4action",
      link: "https://www.eval4action.org/",
    },
    {
      image: evalpartners,
      alt: "Eval4apartners",
      link: "https://www.evalpartners.org/",
    },
    {
      image: ioce,
      alt: "ioce",
      link: "https://www.ioce.net/",
    },
    {
      image: gpfe,
      alt: "gpfe",
      link: "https://globalparliamentarianforum.wordpress.com/",
    },
    {
      image: clear,
      alt: "clear",
      link: "http://www.clear-aa.co.za/",
    },
    {
      image: clear,
      alt: "clearfa",
      link: "https://clearfa.org/",
    },

    {
      image: rfe,
      alt: "rfe",
      link: "https://www.rfevaluation.org/",
    },
    {
      image: twende,
      alt: "twende",
      link: "http://www.twendembele.org/",
    },

    {
      image: unicef,
      alt: "unicef",
      link: "https://www.ungm.org/Shared/KnowledgeCenter/Pages/UNICEF",
    },
    {
      image: unw,
      alt: "unw",
      link: "https://www.unwomen.org/en",
    },

    {
      image: undp,
      alt: "undp",
      link: "https://www.undp.org/content/undp/en/home.html",
    },

    {
      image: idev,
      alt: "idev",
      link: `http://idev.afdb.org/${lang}`,
    },
  ];

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/testimonial/${lang}`,
    };
    axios(config)
      .then(function (response) {
        var res = response.data.results;
        setTestimonials(res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const next = () => {
    if (move !== -672) {
      setRight(true);
      setLeft(true);
      const slider = document.getElementById("slider");
      slider.style.transform = `translateX(${move - 336}px`;
      setMove(move - 336);
    } else {
      setLeft(true);
      setRight(false);
    }
  };

  const prev = () => {
    if (move !== 0) {
      setLeft(true);
      setRight(true);
      const slider = document.getElementById("slider");
      slider.style.transform = `translateX(${move + 336}px`;
      setMove(move + 336);
    } else {
      setRight(true);
      setLeft(false);
    }
  };

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  return (
    <div className="bg-white">
      {show && <Menu show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      {showPayment && (
        <PaymentModal setShowPayment={() => setShowPayment(false)} />
      )}
      <div className="bg-navbarBg  ">
        <div className=" xl:w-[1200px] lg:w-[950px] w-full mx-auto  lg:px-0 px-5 pt-[0px]">
          <div className="xl:grid xl:grid-cols-[53%_47%] block  xl:pl-7 pl-0  xl:pt-5">
            <div className="xl:w-[530px] w-full flex flex-col justify-between mr-4 ">
              <div>
                <h1 className="xl:text-[36px]  lg:text-[38px] text-[25px] font-sBold xl:text-left  text-center leading-10">
                  {t(
                    "Encouraging the integration of assessment into national decision-making processes."
                  )}
                </h1>
                <p className="font-normal mt-[25px] text-black xl:text-left text-center w-full xl:w-[90%]">
                  {t(
                    "National Chapters are an integral part of APNODE’s advocacy/outreach, capacity building, communication and resource mobilization strategies, including collecting APNODE membership fees and recruiting new members."
                  )}
                </p>
              </div>
              <button
                onClick={() => setShowPayment(true)}
                className="bg-buttonColor text-white px-[30px] py-[9px] text-[17px] w-fit mt-6 mb-2"
              >
                {t("Click to Support Us")}
              </button>
            </div>
            <div className="xl:relative xl:block hidden">
              <img className="relative z-[4] ml-auto " src={m1} />
            </div>
          </div>
        </div>
      </div>
      <div className="xl:w-[1200px] lg:w-[950px] w-full mx-auto xl:grid xl:grid-cols-[1fr_1fr] block gap-[60px] lg:px-0  px-5 lg:mt-[88px] mt-[30px]">
        <div className="xl:block hidden">
          <img src={we} className="w-[100%]" />
        </div>
        <div>
          <h2 className="font-sBold text-4xl mb-[15px]  ">
            {t("WHAT WE DO")}
          </h2>
          <p>
            {t(
              "APNODE’s mandate is to strengthen the capacity of African parliamentarians for quality oversight, policymaking, and national decision-making by ensuring appreciation, demand, and utilization of evaluations in their daily work. To this end, the Network serves as a platform via which members of parliament (MPs) share information, knowledge and experiences on evidence use. APNODE also facilitates trainings, dialogue forums, learning events and networking opportunities for parliamentarians; enables the participation of African MPs in international evaluation forums; and, actively encourages knowledge capture for wider dissemination amongst its stakeholders. Recognizing that parliamentarians are but one among many players in national evaluation systems, APNODE strongly focuses on collaboration and partnerships."
            )}
            <br />
            <br />
            {t(
              "APNODE systematically gathers, collates, repackages and disseminates information and knowledge on evidence-use to its members via its Weekly What’s New Flyer, LinkedIn Group platform, Quarterly Newsletter and website. We partner with the African Evaluation Association (AfrEA), Eval4Action, Centers for Learning on Evaluation and Results (CLEAR-AA/FA), Reseau Francophone d’Evaluation (RFE), Twende Mbele, UNICEF and UN-Women to develop the capacity of African MPs, and to facilitate their participation in national, regional and global conferences and events. We further support the establishment of APNODE chapters at the national level to enhance and advance the evolution of an evaluation culture."
            )}
          </p>
        </div>
      </div>
      <div className="xl:w-[1200px] lg:w-[950px] w-full mx-auto lg:grid lg:grid-cols-[1fr_1fr] block gap-[60px] lg:px-0  px-5 mt-[88px]">
        <div>
          <h2 className="font-sBold lg:text-4xl text-[28px]  mb-[30px]">
            {t("OBJECTIVES")}
          </h2>
          <ol className="lg:w-[450px] w-full">
            {objs.map((ele, i) => (
              <li className="flex mb-[30px]" value={ele.value}>
                <span className="mr-[23px] text-2xl text-buttonColor font-sMedium">
                  {ele.value}
                </span>
                <span className=" text-black">{ele.text}</span>
              </li>
            ))}
          </ol>
        </div>
        <div>
          <div className="relative ">
            <img src={objsPic} className="lg:block hidden" />
          </div>
        </div>
      </div>
      <div className="xl:w-[1200px] lg:w-[950px] w-full mx-auto block gap-[60px] px-5 mt-[88px]">
        <div>
          <div>
            <h3 className="appPText mb-[15px] lg:text-4xl text-[28px]  text-center font-sBold uppercase">
              {t("Strategic Partners")}
            </h3>
          </div>
        </div>
        <div className="grid lg:grid-cols-6 grid-cols-2 px-2 items-center justify-center gap-5  mb-10  mt-4">
          {logos.map((ele, i) => (
            <a key={i} href={ele.link} className="mb-4" target="_blank">
              <img
                alt={ele.alt}
                src={ele.image}
                className="lg:w-[136px] w-[100px] mx-auto partner-img h-auto"
              />
            </a>
          ))}
        </div>
      </div>
      <div>
        <button
          onClick={() => setShowPayment(true)}
          className="border mx-auto border-buttonColor mt-5 block px-[30px] py-[9px] w-fit text-buttonColor lg:text-base text-[14px]"
        >
          {t("Click to Support Us")}
        </button>
      </div>
      <Interested />
      {/* <div className="py-[70px] xl:w-[1100px] lg:w-[950px] md:w-[700px] w-full mx-auto">
        <h3 className="text-center lg:text-[38px] text-[25px] font-sBold uppercase text-black">
          {t("Success Stories")}
        </h3>

        <div className="flex items-center xl:mt-24 mt-10 justify-between">
          <Prev
            stroke={`${left ? "black" : "grey"}`}
            onClick={prev}
            className="cursor-pointer w-[100px]"
          />
          <div className=" w-[968px] w-full  overflow-hidden">
            <div id="slider" className="flex  gap-[40px] transition-all">
              {testimonials.map((ele, i) => (
                <SuccessCard key={i} data={ele} />
              ))}
            </div>
          </div>
          <Next
            stroke={`${right ? "black" : "grey"}`}
            className="cursor-pointer w-[100px]"
            onClick={next}
          />
        </div>
      </div> */}
      {/* <div className="max-w-2xl mx-auto">
        <Donation2 />
      </div> */}
      <Footer />
    </div>
  );
};

export default SupportUs;
