import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as Down } from "../assets/svgs/chevron-down.svg";
import { ReactComponent as Flag } from "../assets/svgs/flag.svg";
import { ReactComponent as Arrow } from "../assets/svgs/chevron-arrow-down.svg";
import { ReactComponent as Next } from "../assets/svgs/sub-right.svg";
import french from "../assets/svgs/french.svg";
import { useTranslation } from "react-i18next";

const Menu = ({ show, color }) => {
  const location = useLocation();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const { t, i18n } = useTranslation();

  const [lang, setLang] = useState(window.localStorage.getItem("apnode_lang"));

  var ele;
  useEffect(() => {
    ele = document.getElementById("chev");
  });

  useEffect(() => {
    if (show === null) {
      var menu = document.getElementById("menu");
    }
  });

  const handleClick = () => {
    setShowSubMenu(!showSubMenu);
    if (!ele.classList.contains("rotate-chevron")) {
      ele.classList.add("rotate-chevron");
    } else {
      ele.classList.remove("rotate-chevron");
    }
  };

  const changeLang = (e) => {
    i18n.changeLanguage(e);
    window.localStorage.setItem("apnode_lang", e);
    setLang(e);
    window.location.reload();
  };

  return (
    <div
      id="menu"
      className={`menu-main-wrapper event-card-shadow navbar-wrapper ${
        color == "white" ? "bg-[#fff]" : "bg-[#FBF7CF]"
      }`}
    >
      <div className="pt-6 w-full mx-auto px-5">
        <div className="flex justify-between items-center   ">
          <div
            onClick={() => setShowLang(!showLang)}
            className="flex items-center cursor-pointer mr-4 z-[70] "
          >
            <Flag className="mr-4" />
            <label
              htmlFor="default-toggle"
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                onChange={(e) =>
                  e.target.checked ? changeLang("fr") : changeLang("en")
                }
                type="checkbox"
                checked={lang === "fr" ? "checked" : ""}
                id="default-toggle"
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-[#0A3161] peer-focus:outline-none rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full  after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#ED2939]"></div>
            </label>
            <img src={french} className="ml-4" />
          </div>
        </div>
      </div>
      <div className="menu-wrapper">
        <ul className="menu-list">
          <li
            onClick={() => handleClick()}
            className={`active-menu-link cursor-pointer flex items-center justify-center ${
              location.pathname === "/" ? "text-secondaryText" : "text-navText"
            } `}
          >
            <span className="mr-5">{t("Home")}</span>
            <Down id="chev" className="w-5 h-5 transition-all" />
          </li>
          {showSubMenu && (
            <ul className="transition-all">
              <li
                style={{ fontSize: "1.6rem" }}
                className={`inactive-menu-link italic text-[20px] ${
                  location.pathname.match("about-us")
                    ? "text-buttonColor border-l-[3px] font-sBold border-l-buttonColor "
                    : ""
                }            `}
              >
                <a href="/about-us">{t("About Us")}</a>
              </li>
              <li
                style={{ fontSize: "1.6rem" }}
                className={`inactive-menu-link italic ${
                  location.pathname.match("team")
                    ? "text-buttonColor border-l-[3px] font-sBold border-l-buttonColor "
                    : ""
                }    `}
              >
                <a href="/team">{t("Executive")}</a>
              </li>
              <li
                style={{ fontSize: "1.6rem" }}
                className={`inactive-menu-link italic  ${
                  location.pathname.match("history")
                    ? "text-buttonColor border-l-[3px] font-sBold border-l-buttonColor "
                    : ""
                }    `}
              >
                <a href="/history">{t("Milestones")}</a>
              </li>
            </ul>
          )}
          <li
            className={`inactive-menu-link  ${
              location.pathname.match("membership")
                ? "text-buttonColor border-l-[3px] font-sBold border-l-buttonColor "
                : ""
            }    `}
          >
            <a href="/membership">{t("Membership")}</a>
          </li>
          <li
            className={`active-menu-link  ${
              location.pathname.match("resources")
                ? "text-buttonColor border-l-[3px] font-sBold border-l-buttonColor "
                : ""
            }    `}
          >
            <a href="/resources">{t("Resources")}</a>
          </li>
          <li
            className={`active-menu-link  ${
              location.pathname.match("news")
                ? "text-buttonColor border-l-[3px] font-sBold border-l-buttonColor "
                : ""
            }    `}
          >
            <a href="/news">{t("News")}</a>
          </li>
          <li
            className={`inactive-menu-link  ${
              location.pathname.match("events")
                ? "text-buttonColor border-l-[3px] font-sBold border-l-buttonColor "
                : ""
            }    `}
          >
            <a href="/events">{t("Events")}</a>
          </li>

          <li
            className={`active-menu-link  ${
              location.pathname.match("contact-us")
                ? "text-buttonColor border-l-[3px] font-sBold border-l-buttonColor "
                : ""
            }    `}
          >
            <a href="/contact-us">{t("Contact Us")}</a>
          </li>

          <li
            className={`active-menu-link text-white bg-buttonColor   ${
              location.pathname.match("support-us")
                ? "text-buttonColor border-l-[3px] font-sBold border-l-buttonColor "
                : ""
            }    `}
          >
            <a href="/support-us">{t("Support Us")}</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
