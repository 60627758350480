import React, { useEffect, useRef } from "react";
import SearchModal from "./SearchModal";
const SearchMain = ({ setShowSearch }) => {
  return (
    <div
      id="search-main"
      className="overlay-modal w-screen flex justify-center items-center h-screen z-50 fixed top-0 p-5"
    >
      <div className="w-[750px] rounded-md">
        <SearchModal setShowSearch={() => setShowSearch()} />
      </div>
    </div>
  );
};

export default SearchMain;
