import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import newsImage from "../assets/images/newsImage.jpg";
import Footer from "../components/Footer";
import n1 from "../assets/images/n1.png";
import n2 from "../assets/images/n2.png";
import taxi from "../assets/images/taxi.png";
import news_new from "../assets/images/news_new.jpg";
import { ReactComponent as Left } from "../assets/svgs/pag-left.svg";
import { ReactComponent as Right } from "../assets/svgs/pag-right.svg";
import DonationsMain from "../components/Donations/DonationsMain";
import { useState } from "react";
import axios from "axios";
import { t } from "i18next";
import Menu from "../components/Menu";
import SearchMain from "../components/SearchMain";

const News = () => {
  // const [showNews, toggle] = useState(false);
  const [news, setNews] = useState([]);
  const [categories, setCatogeries] = useState([]);
  const [category, setCategory] = useState();
  const [latest, setLatest] = useState({});
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [allNews, setAllNews] = useState([]);
  const lang = window.localStorage.getItem("apnode_lang");
  const [showSearch, setShowSearch] = useState(false);
  const [steps, setSteps] = useState(6);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/resources/general/categories`,
    };
    axios(config)
      .then(function (response) {
        setCatogeries(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/news/general/news/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setLatest(response.data.results[0]);
        setAllNews(response.data.results);
        setNews(response.data.results.splice(0, steps));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const filter = (cat) => {
    var config = {
      method: "get",
      url: `/v1/news/general/categories/${cat}/news/${lang}`,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.results);
        if (response.data.results.length) {
          setLatest(response.data.results[0]);
          setNews(response.data.results);
        } else {
          setLatest([]);
          setNews([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleClick = (data) => {
    setCategory(data);
    filter(data);
  };

  const truncate = (str) => {
    return str.length > 300 && str ? str.substring(0, 280) + "..." : str;
  };

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  const loadMore = () => {
    let newNews = allNews.slice(steps, steps + 2);
    console.log("->", ...newNews);
    setSteps(steps + 2);
    setNews([...news, ...newNews]);
  };

  return (
    <div>
      {show && <Menu show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div className="bg-navbarBg  ">
        <div className=" xl:w-[1200px] lg:w-[950px] w-full mx-auto xl:px-0 px-5 pt-[0px] ">
          <div className="xl:grid grid-cols-[53%_47%]  flex items-center justify-between block  lg:pl-7 pl-0 pt-10 ">
            <div className="xl:w-[530px] w-full  flex flex-col ">
              <div className="xl:translate-y-[-100px]">
                <h1 className="xl:text-[36px] uppercase lg:text-[38px] text-[25px] font-sBold   text-center">
                  {t("News")}
                </h1>
                <p className="font-normal mt-[10px] lg:text-left text-center  text-appPText">
                  {t(
                    "At APNODE, we sensitize national parliaments and parliamentarians about the importance of evaluation for oversight, policy and decision making."
                  )}
                </p>
              </div>
            </div>
            <div className="relative xl:block hidden">
              <img src={news_new} className="" />
            </div>
          </div>
        </div>
      </div>
      <div className=" xl:w-[1200px] w-full mx-auto xl:px-0 px-5 xl:pt-[90px] pt-[30px]">
        <h2 className="text-appPText text-4xl text-center mx-auto mb-[30px] font-sSemiBold">
          {t("Our Latest News")}
        </h2>
        <div className="flex flex-wrap gap-[10px] mx-auto justify-center ">
          {categories.map((ele, i) => (
            <button
              onClick={() => handleClick(ele.slug)}
              key={i}
              className={` ${
                ele.slug === category ? "bg-buttonColor" : "bg-white"
              }   ${
                ele.slug === category ? "text-white" : "text-black"
              }   text-white px-[10px] py-[9px] text-sm`}
            >
              {t(ele.name)}
            </button>
          ))}
        </div>
        {/* latest */}
        {latest.length ? (
          <div className=" mt-[30px] mb-20">
            <img src={latest?.image} />
            <div>
              <div className="mt-[40px] text-[11px]  flex">
                {latest.categories?.length > 0
                  ? latest?.categories.map((ele, i) => (
                      <p className="font-sSemiBold mr-[20px]">{t(ele.name)}</p>
                    ))
                  : null}
                <p className="text-formText ">
                  {new Date(latest?.created_at).toDateString()}
                </p>
              </div>
              <div className="mt-[17px]">
                <h6 className="text-2xl font-sSemiBold">{latest?.title}</h6>
                {latest.description ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: truncate(latest?.description),
                    }}
                  ></p>
                ) : null}
                <a
                  href={`/news/${latest?.slug}`}
                  className="text-buttonColor mt-[30px] font-sMedium w-fit border-b-2 pb-1 border-b-buttonColor block cursor-pointer"
                >
                  {t("Read More")}
                </a>
              </div>
            </div>
          </div>
        ) : null}

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-[47px] mt-[24px] mb-2">
          {news.length
            ? news.map((ele, i) => (
                <div key={i}>
                  <img className="w-full h-[350px]" src={ele.image} />
                  <div className="mt-[40px] text-[12px]  flex">
                    {ele?.categories.map((ele, i) => (
                      <p className="font-sSemiBold mr-[20px]">{t(ele.name)}</p>
                    ))}
                    <p className="text-formText ">
                      {new Date(ele.published_date).toDateString()}
                    </p>
                  </div>
                  <div className="mt-[17px]">
                    <h6 className="text-2xl font-sSemiBold">{ele.title}</h6>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: truncate(ele?.description),
                      }}
                      className="text-appText text-[15px] mt-[8px]"
                    ></p>
                    <a
                      href={`/news/${ele?.slug}`}
                      className="text-buttonColor mt-[30px] font-sMedium w-fit border-b-2 pb-1 border-b-buttonColor block cursor-pointer"
                    >
                      {t("Read More")}
                    </a>
                  </div>
                </div>
              ))
            : null}
        </div>

        <div>
          {steps < allNews.length && (
            <button
              onClick={loadMore}
              className="border mx-auto border-buttonColor mt-5 block px-[30px] py-[9px] w-fit text-buttonColor mb-4 lg:text-base text-[14px]"
            >
              {t("Load more")}
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default News;
