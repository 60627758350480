import axios from "axios";
import React, { useEffect, useState } from "react";
import Lady from "../assets/images/lady-on-phone.png";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import quotes from "../assets/svgs/quotes.svg";
import { t } from "i18next";
import { ReactComponent as LinkedIn } from "../assets/svgs/linkedin.svg";
import { ReactComponent as Twitter } from "../assets/svgs/twitter.svg";
import { ReactComponent as Youtube } from "../assets/svgs/youtube.svg";
import { BallSpinner } from "react-spinners-kit";
import Menu from "../components/Menu";
import SearchMain from "../components/SearchMain";

const ContactUs = () => {
  const [count, setCount] = useState(150);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [text, setText] = useState({});
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (count <= 0) {
      setCount(0);
    }
  }, []);

  useEffect(() => {
    if (
      first_name == "" ||
      last_name == "" ||
      email == "" ||
      message == "" ||
      subject == "" ||
      last_name == ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [first_name, last_name, email, message, subject, last_name]);

  const [subjects, setSubjects] = useState([]);
  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/contact-user/subjects`,
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        setSubjects(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
    var data = JSON.stringify({
      first_name,
      last_name,
      email,
      message,
      subject,
      last_name,
    });

    var config = {
      method: "post",
      url: "/v1/apnode/general/contact-us/",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        setLoading(false);
        setFirstName("");
        setLastName("");
        setSubject("");
        setEmail("");
        setMessage("");
        setLoading("");
        setText(response);
        setTimeout(() => {
          setText("");
        }, 3000);
      })
      .catch(function (error) {
        setLoading(false);
        setText("Submission Failed");
        setTimeout(() => {
          setText("");
        }, 3000);
        console.log(error);
      });
  };

  const handleTextArea = (e) => {
    setCount(count - 1);
    setMessage(e.target.value);
  };

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  return (
    <div className="bg-appBg">
      {show && <Menu show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div className="bg-navbarBg">
        <div className="xl:max-w-[1200px] mx-auto pt-10">
          <h2 className="font-sBold text-2xl pb-[12px] xl:px-0 px-3">
            {t("Contact Us")}
          </h2>
        </div>
      </div>
      <div className="sharp-bg mb-[100px]">
        <div className="xl:max-w-[1200px] bg-white  shadow-md mx-auto  grid xl:grid-cols-[55%_45%] grid-cols-1">
          <div className="md:px-[60px] px-4 pt-[38px]">
            <p className="text-formText">{t("Please fill up the form.")}</p>
            <form className="mt-[32px]">
              <div class="grid md:grid-cols-2 md:gap-6 mb-[10px]">
                <div class="relative z-0 mb-6 w-full group">
                  <label class="block mb-[7px] text-sm font-sMedium text-gray-900">
                    {t("First Name")}{" "}
                    <span className="text-red-500 text-xs">
                      *({t("required")})
                    </span>
                  </label>
                  <input
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    className=" border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                    placeholder={t("First Name")}
                  />
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <label class="block mb-[7px] text-sm font-sMedium text-gray-900">
                    {t("Last Name")}{" "}
                    <span className="text-red-500 text-xs">
                      *({t("required")})
                    </span>
                  </label>
                  <input
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    className=" border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                    placeholder={t("Last Name")}
                  />
                </div>
              </div>
              <div className="mb-[25px]">
                <div class="relative z-0 mb-6 w-full group">
                  <label class="block mb-[7px] text-sm font-sMedium text-gray-900">
                    {t("Email")}{" "}
                    <span className="text-red-500 text-xs">
                      *({t("required")})
                    </span>
                  </label>
                  <input
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className=" border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                    placeholder={t("Email")}
                  />
                </div>
              </div>
              <div>
                <label class="block mb-[7px] text-sm font-sMedium text-gray-900">
                  {t("Subject")}{" "}
                  <span className="text-red-500 text-xs">
                    *({t("required")})
                  </span>
                </label>
                <div className="grid md:grid-cols-2 grid-cols-1 justify-between">
                  {subjects.map((ele, i) => (
                    <div key={i} className="flex items-center mb-4">
                      <input
                        value={ele.title}
                        type="radio"
                        name="subject"
                        checked={subject === ele.title}
                        onChange={(e) => setSubject(e.target.value)}
                        className="w-5 h-5  radio-item mr-2"
                      />
                      <label class="block mb-2 text-sm font-sLight text-[#555555] drop">
                        {t(ele.title)}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="relative mt-[31px]">
                <label class="block mb-[7px] text-sm font-sMedium text-gray-900">
                  {t("Message")}{" "}
                  <span className="text-red-500 text-xs">
                    *({t("required")})
                  </span>
                </label>
                <textarea
                  onChange={(e) => handleTextArea(e)}
                  value={message}
                  rows="6"
                  className=" resize-none border text-sm font-sLight border-formBorder   focus:outline-none  focus:rounded-none block w-full py-[14px] pl-[17px] input-field "
                ></textarea>
                <span className="text-[13px] text-formText font-sMedium absolute right-[11px] bottom-[7px]">
                  {count}
                </span>
              </div>
              <div className="mt-[24px] mb-5 flex items-center">
                <button
                  disabled={disabled}
                  onClick={submit}
                  className={`border disabled:opacity-40
                  ${
                    loading
                      ? "opacity-60 cursor-not-allowed"
                      : "opacity-100 cursor-pointer"
                  }
                  border-buttonColor text-buttonColor  px-[35px] py-[9px] `}
                >
                  {loading ? <BallSpinner color="#EC892A" /> : t("Submit")}
                </button>
                <p
                  className={`text-sm  ml-2 ${
                    text.status == 201 ? "text-green-600" : "text-red-600"
                  }  `}
                >
                  {text.data}
                </p>
              </div>
            </form>
          </div>
          <div className="items-start flex xl:block hidden">
            <div className="max-w-[500px] bg-appPText px-6 py-[41px] relative mt-10  rounded-md">
              <img className="absolute right-0 top-[-10px]" src={quotes} />
              <h6 className="text-white font-sMedium mb-[10px]  text-2xl">
                {t("APNODE Secretariat")}
              </h6>
              <p className="text-buttonColor font-sLight text-sm leading-[22.4px]">
                {t(
                  "APNODE Secretariat c/o Independent Development Evaluation (IDEV)"
                )}
              </p>
              <p className="text-white font-sLight text-sm leading-[22.4px]">
                {t("African Development Bank")}
                <br />
                01 BP 1387 Abidjan 01 Côte d’Ivoire
              </p>
              <p className="text-white font-sLight text-sm leading-[22.4px]">
                {t("Phone")}: +225 27 20 26 47 90 / +225 27 20 26 45 33 l
              </p>
              <div className="flex justify-center xl:justify-start mt-4">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/groups/8734908/"
                  className="w-[34px] h-[34px] border border-white rounded-full flex justify-center items-center"
                >
                  <LinkedIn className="footer-link" />
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/APNODE_Official"
                  className="w-[34px] h-[34px] border border-white rounded-full mx-[22px] flex justify-center items-center"
                >
                  <Twitter className="footer-link" />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCj1Rlekws0-OJ0PJgRdU2hg"
                  className="w-[34px] h-[34px] border border-white rounded-full mr-[22px] flex justify-center items-center"
                >
                  <Youtube stroke="#fff" className="footer-link" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
