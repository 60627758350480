import React from "react";
import CreateEventButton from "./CreateEventButton";
import SmallCalendar from "./SmallCalendar";
import Labels from "./Labels";
export default function Sidebar() {
  return (
    <div className="border rounded p-5 lg:w-[304px] w-full h-fit">
      <SmallCalendar />
    </div>
  );
}