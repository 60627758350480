import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Faq from "./pages/Faq";
import ContactUs from "./pages/ContactUs";
import Events from "./pages/Events";
import MainEvent from "./pages/MainEvent";
import AboutUs from "./pages/AboutUs";
import Team from "./pages/Team";
import History from "./pages/History";
import Resources from "./pages/Resources";
import Membership from "./pages/Membership";
import MembershipForm from "./pages/MembershipForm";
import News from "./pages/News";
import NewsArticle from "./pages/NewsArticle";
import SupportUs from "./pages/SupportUs";
import { useEffect } from "react";
import SeeAll from "./pages/SeeAll";
import Gallery from "./pages/Gallery";
import GalleryDetails from "./pages/GalleryDetails";
import GalleryPhoto from "./pages/GalleryPhoto";

function App() {
  useEffect(() => {
    console.log(localStorage.getItem("apnode_lang"));
    if (!localStorage.getItem("apnode_lang")) {
      localStorage.setItem("apnode_lang", "en");
    }
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="events">
          <Route index element={<Events />} />
          <Route path=":id" element={<MainEvent />} />
        </Route>
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/support-us" element={<SupportUs />} />
        <Route path="/team" element={<Team />} />
        <Route path="/history" element={<History />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/resources/gallery" element={<Gallery />} />
        <Route path="/resources/gallery/:slug" element={<GalleryDetails />} />
        <Route path="/resources/gallery/:slug/photos" element={<GalleryPhoto />} />
        <Route path="/resources/:type" element={<SeeAll />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/membership/form" element={<MembershipForm />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewsArticle />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
