import axios from "axios";
import { t } from "i18next";
import React, { useState } from "react";
import { useEffect } from "react";
import { ReactComponent as Search } from "../assets/svgs/search.svg";
import PageWrapper from "../components/PageWrapper";
import Slideshow from "../components/Resources/Slideshow";
import { useRef } from "react";
import { useFetcher } from "react-router-dom";

const Gallery = () => {
  const [albums, setAlbums] = useState([]);
  const [allAlbums, setAllAlbums] = useState([]);
  const [category, setCategory] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef();
  useEffect(() => {
    axios.get("https://api.apnode.org/api/v1/gallery/albums/").then((res) => {
      console.log(res.data.results);
      setAlbums(res.data.results);
      setAllAlbums(res.data.results);
    });
  }, []);

  const filter = (cat) => {
    var config = {
      method: "get",
      url: `https://api.apnode.org/api/v1/gallery/${cat}/albums/`,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setAlbums(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleClick = (data) => {
    setCategory(data);
    filter(data);
  };

  useEffect(() => {
    if (searchQuery == "" ) {
      setAlbums(allAlbums);
    } else {
      let filteredAlbums = allAlbums.filter((obj) =>
        obj.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setAlbums(filteredAlbums);
    }
  }, [searchQuery]);
  const categories = ["AGM", "Trainings", "National Chapters", "Learning"];
  return (
    <PageWrapper>
      <div className="min-h-screen">
        <div
          id="gallery"
          className="flex w-full justify-between items-center lg:mt-[70px] mt-10 mb-4"
        >
          <h2 className="text-appPText uppercase text-2xl font-sBold ">
            {t("Albums")}
          </h2>
          <div className="flex bg-navbarBg rounded items-center ">
            <Search fill="#EC892A" stroke="#EC892A" className="scale-[0.5]" />
            <input
              className="bg-navbarBg outline-none p-2 text-sm rounded"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-[10px] mx-auto justify-center my-5 mt-[100px]">
          {categories.map((ele, i) => (
            <button
              onClick={() => handleClick(ele)}
              key={i}
              className={` ${
                ele === category ? "bg-buttonColor" : "bg-white"
              }   ${
                ele === category ? "text-white" : "text-black"
              }   text-white px-[10px] py-[9px] text-sm`}
            >
              {t(ele)}
            </button>
          ))}
        </div>
        {albums.length > 0 ? (
          <Slideshow albums={albums} />
        ) : albums.length == 0 ? (
          <p>no albums...</p>
        ) : (
          <p>Loading...</p>
        )}

        <div className="grid xl:grid-cols-4 lg:grid-cols-3 gap-[30px] mt-10">
          {albums.map((ele, i) => (
            <a
              href={`/resources/gallery/${ele.slug}`}
              key={i}
              className="relative gallery-card block  w-[250px] "
            >
              <div className="w-[250px] h-[300px] border border-gray-400 absolute translate-x-[-5px] translate-y-[-5px] z-[-1]"></div>
              <div className="w-[250px] h-[300px] border border-gray-400 absolute translate-x-[-10px] translate-y-[-10px] z-[-1]"></div>
              <div className="w-[250px]  h-[300px] z-10 absolute hover:bg-[rgba(0,0,0,0.5)] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  "></div>

              <img
                className="w-[250px]  h-[300px] z-2 object-cover transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  gallery-main-image duration-300"
                src={ele.image}
                alt=""
              />
              <p className=" left-[15px] text-black mt-3 image-desc text-sm text-center  transition-all delay-200 z-10">
                {ele.description}
              </p>
            </a>
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Gallery;
