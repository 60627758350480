import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import main from "../assets/images/main.jpg";
import container from "../assets/images/container.jpg";
// import Nowgua from "../assets/svgs/Nowgua.svg";
import Nowgua from "../assets/svgs/Nowgua.svg";
import Hp from "../assets/svgs/Hp.svg";
import Ebay from "../assets/svgs/Ebay.svg";
import AppDrog from "../assets/svgs/AppDrog.svg";
import Veloquant from "../assets/svgs/Veloquant.svg";
import Israel from "../assets/svgs/Israel Discount Bank.svg";
import Adopt from "../assets/svgs/Adopt A Contractor.svg";
import Manage from "../assets/svgs/Manage Your Trip.svg";
import Dignostic from "../assets/svgs/Dignostic AI.svg";
import City from "../assets/svgs/City Hive.svg";
import Twik from "../assets/svgs/Twik.svg";
import Beam from "../assets/svgs/Beam Up.svg";
import Abillisense from "../assets/svgs/Abillisense.svg";
import FellowMe from "../assets/svgs/FellowMe.svg";
import avatar from "../assets/images/avatar.png";
import quotes from "../assets/svgs/quotes.svg";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import axios from "axios";

import afrea from "../assets/images/afrea.jpg";
import home_main from "../assets/images/home_main.jpeg";
import eval4 from "../assets/images/eval4.png";
import evalpartners from "../assets/images/evalpartners.jpg";
import ioce from "../assets/images/ioce.png";
import gpfe from "../assets/images/gpfe.png";
import clear from "../assets/images/clear.jpg";
import rfe from "../assets/images/rfe.jpg";
import twende from "../assets/images/twende.png";
import unicef from "../assets/images/unicef.png";
import unfpa from "../assets/images/unfpa.png";
import idev from "../assets/images/idev.png";
import unw from "../assets/images/unw.png";
import undp from "../assets/images/undp.png";
import aboutus from "../assets/images/aboutus-hero.jpg";
import { ReactComponent as Prev } from "../assets/svgs/slide-prev.svg";
import { ReactComponent as Next } from "../assets/svgs/slide-next.svg";
import SuccessCard from "../components/SuccessCard";
import Menu from "../components/Menu";
import SearchModal from "../components/SearchModal";
import SearchMain from "../components/SearchMain";
import Interested from "../components/Interested";

const Home = () => {
  const arr = [2014, 2015, 2016, 2017, 2018, 2020, 2022, 2021, 2022];
  const lang = window.localStorage.getItem("apnode_lang");

  const logos = [
    {
      image: afrea,
      alt: "AfrEa",
      link: "https://afrea.org/",
    },
    {
      image: eval4,
      alt: "Eval4action",
      link: "https://www.eval4action.org/",
    },
    {
      image: evalpartners,
      alt: "Eval4apartners",
      link: "https://www.evalpartners.org/",
    },
    {
      image: ioce,
      alt: "ioce",
      link: "https://www.ioce.net/",
    },
    {
      image: gpfe,
      alt: "gpfe",
      link: "https://globalparliamentarianforum.wordpress.com/",
    },
    {
      image: clear,
      alt: "clear",
      link: "http://www.clear-aa.co.za/",
    },
    {
      image: clear,
      alt: "clearfa",
      link: "https://clearfa.org/",
    },

    {
      image: rfe,
      alt: "rfe",
      link: "https://www.rfevaluation.org/",
    },
    {
      image: twende,
      alt: "twende",
      link: "http://www.twendembele.org/",
    },

    {
      image: unicef,
      alt: "unicef",
      link: "https://www.ungm.org/Shared/KnowledgeCenter/Pages/UNICEF",
    },
    {
      image: unw,
      alt: "unw",
      link: "https://www.unwomen.org/en",
    },

    {
      image: undp,
      alt: "undp",
      link: "https://www.undp.org/content/undp/en/home.html",
    },

    {
      image: idev,
      alt: "idev",
      link: `http://idev.afdb.org/${lang}`,
    },
    {
      image: unfpa,
      alt: "UNFPA EO",
      link: `${lang === 'fr'? 'https://www.unfpa.org/fr/evaluation': 'https://www.unfpa.org/evaluation'}`,
    }
  ];
  const { t } = useTranslation();
  let menuBtn;

  const [testimonials, setTestimonials] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [move, setMove] = useState(0);
  const [left, setLeft] = useState(false);
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [right, setRight] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/testimonial/${lang}`,
    };
    axios(config)
      .then(function (response) {
        var temp = [];
        var res = response.data.results;
        setTestimonials(res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/history/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setMilestones(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const next = () => {
    if (move !== -672) {
      setRight(true);
      setLeft(true);
      const slider = document.getElementById("slider");
      slider.style.transform = `translateX(${move - 336}px`;
      setMove(move - 336);
    } else {
      setLeft(true);
      setRight(false);
    }
  };

  const prev = () => {
    if (move !== 0) {
      setLeft(true);
      setRight(true);
      const slider = document.getElementById("slider");
      slider.style.transform = `translateX(${move + 336}px`;
      setMove(move + 336);
    } else {
      setRight(true);
      setLeft(false);
    }
  };

  const handleShow = (data) => {
    if (menuBtn) {
      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        console.log("else");
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  return (
    <div>
      {show && <Menu show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />
      <div className="bg-navbarBg  ">
        <div className=" xl:w-[1200px] lg:w-[950px] w-full mx-auto xl:px-0 px-5 pt-[0px] ">
          <div className="xl:grid grid-cols-2 block  pl-0 pt-5 ">
            <div className="xl:w-[530px] w-full flex flex-col justify-between mr-4">
              <div>
                <h1 className="xl:text-[36px]  lg:text-[38px] text-[1.5rem] font-sBold xl:text-left  text-center leading-10">
                  <h2>
                    {t(
                      "African Parliamentarians' Network on Development Evaluation"
                    )}
                  </h2>
                </h1>
                <p className="font-normal lg:text-base text-[14px]">
                  {/* {t(
                    "The African Parliamentarians' Network on Development Evaluation (APNODE) is a network of African parliamentarians and development partners promoting the use of evaluation in national decision making to support development effectiveness and inclusive growth."
                  )} */}
                  {t(
                    "APNODE is a network of African parliamentarians and development partners that  strives to bridge the gap between evaluators (who provide impartial evidence) and  parliamentarians (who utilize such evidence). It is a brainchild of 25 parliamentarians  (APNODE’s founding members) from 7 countries. Today, the network’s footprint and  visibility, spans all the corners of the African continent and beyond."
                  )}
                </p>
              </div>
              {/* <a
                href="#learn-more"
                className="bg-buttonColor text-white px-[30px] py-[9px] lg:text-[17px] text-[14px] w-fit xl:mt-2 mt-4 mb-5 xl:ml-0 mx-auto "
              >
                {t("Learn More")}
              </a> */}
            </div>
            <div className="ml-auto xl:block hidden">
              <img className="h-[365px]" src={home_main} />
            </div>
          </div>
        </div>

        {/* <div className="mt-20 abos">
          <img className="mx-auto block" src={container} />
        </div> */}
      </div>
      <div
        id="learn-more"
        className=" max-w-[1200px] mx-auto mb-10  xl:pt-[100px] lg:pt-[50px] px-5 lg:px-0 "
      >
        <div className=" xl:grid grid-cols-2 md:block  gap-5 mt-4  lg:text-base text-[14px]">
          <div className=" bg-appBlack lg:px-10 px-5 lg:mb-0 mb-5  text-white lg:py-[48px] py-[20px] font-raleway">
            <div className="flex items-center font-bold lg:text-[38px] text-[25px] mb-4">
              <h6 className="uppercase">{t("Who We Are")}</h6>
            </div>
            <p className="font-roboto ">
              {t(
                "APNODE promotes the use of evaluation in national decision making to support  development effectiveness and inclusive growth. Launched in March 2014, it was initiated by African parliamentarians based on their recognition of the important  function of evaluation in national decision-making and the crucial role of  parliamentarians in ensuring that evaluation evidence is used for strengthening  oversight. In addition, it focuses on how evaluation can support parliamentarians in  their roles of legislation, budgeting and representation. The Independent  Development Evaluation (IDEV) at the African Development Bank (AfDB) is a founding  member that has hosted the APNODE Secretariat since its inception."
              )}
              <a
                href="/about-us"
                className="border border-buttonColor py-[9px] mt-4  px-[21px] font-sLight text-buttonColor block w-fit"
              >
                {t("Learn More")}
              </a>
            </p>
          </div>

          <div className="lg:px-10 px-5  bg-appBlack text-white  lg:py-[48px] py-[20px] font-raleway">
            <div className="flex  items-center font-bold  lg:text-[38px] text-[25px] mb-4">
              <h6 className="uppercase">{t("What We Do")}</h6>
            </div>
            <p className="font-roboto">
              {t(
                "APNODE’s mandate is to strengthen the capacity of African parliamentarians for quality oversight, policymaking, and national decision-making by ensuring appreciation, demand, and utilization of evaluations in their daily work. To this end, the Network serves as a platform via which members of parliament (MPs) share information, knowledge and experiences on evidence use. APNODE also facilitates trainings, dialogue forums, learning events and networking opportunities for parliamentarians; enables the participation of African MPs in international evaluation forums; and, actively encourages knowledge capture for wider dissemination amongst its stakeholders. Recognizing that parliamentarians are but one among many players in national evaluation systems, APNODE strongly focuses on collaboration and partnerships."
              )}
              <a
                href="/about-us#whatwedo"
                className="border border-buttonColor py-[9px]  mt-4 px-[21px] font-sLight text-buttonColor block w-fit"
              >
                {t("Learn More")}
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="xl:max-w-[1200px] lg:px-0 px-5 md:block lg:max-w-[950px] py-[50px] mx-auto">
        <div>
          <h5 className="font-sBold text-center  lg:text-[36px] text-[25px] uppercase ">
            {t("Milestones")}
          </h5>
          <a
            href="/history"
            className="text-buttonColor text-center block  font-sMedium w-fit mx-auto border-b-2 pb-1 border-b-buttonColor mb-10"
          >
            {t("Learn More")}
          </a>
        </div>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2">
          {milestones.map((ele, i) => {
            return (
              <div key={i} className="max-w-[253px] mb-[50px]">
                <a
                  href={ele.link}
                  className="px-[26px] py-[4px]  text-sm text-black rounded-[3px] border-2 border-buttonColor  font-sSemiBold cursor-pointer "
                >
                  {ele.date}
                </a>
                <p className="mt-[17px] text-sm">{ele.title}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Interested />

      <div className="py-[70px] xl:w-[1200px] lg:w-[950px] md:w-[700px] w-full mx-auto">
        <h3 className="text-center lg:text-[38px] uppercase text-[25px] font-sBold text-black">
          {t("Success Stories")}
        </h3>

        <div className="flex items-center xl:mt-24 mt-10 justify-between">
          <Prev
            stroke={`${left ? "black" : "grey"}`}
            onClick={prev}
            className="cursor-pointer lg:w-[100px] w-[30px]"
          />
          <div className=" w-full  overflow-hidden">
            <div id="slider" className="flex  lg:gap-[40px] gap-[0px] transition-all">
              {testimonials.map((ele, i) => (
                <SuccessCard key={i} data={ele} />
              ))}
            </div>
          </div>
          <Next
            stroke={`${right ? "black" : "grey"}`}
            className="cursor-pointer lg:w-[100px] w-[30px] "
            onClick={next}
          />
        </div>
      </div>

      {/* partners */}

      <div className="xl:w-[1200px] lg:w-[950px] w-full mx-auto block gap-[60px] lg:px-0 px-5 mt-[88px]">
        <div>
          <div>
            <h3 className="appPText mb-[15px] lg:text-4xl text-[28px]  text-center font-sBold uppercase">
              {t("Strategic Partners")}
            </h3>
          </div>
        </div>
        <div className="grid lg:grid-cols-6  grid-cols-2 px-2 items-center justify-center gap-5  mb-10  mt-4">
          {logos.map((ele, i) => (
            <a key={i} href={ele.link} className="mb-4" target="_blank">
              <img
                alt={ele.alt}
                src={ele.image}
                className="lg:w-[136px] w-[100px] mx-auto partner-img item h-auto"
              />
            </a>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
