import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import aboutus from "../assets/images/team-hero.jpeg";
import building from "../assets/images/building.png";
import vision from "../assets/images/vision.jpg";
import we from "../assets/images/we.jpg";
import o1 from "../assets/images/o1.jpg";
import o2 from "../assets/images/o2.jpg";
import o3 from "../assets/images/o3.jpg";
import a1 from "../assets/images/a1.jpg";
import a2 from "../assets/images/a2.jpg";
import objsPic from "../assets/images/objs.jpg";
import we_do from "../assets/images/we_do.jpg";
import objectives from "../assets/images/objectives.jpg";
import afrea from "../assets/images/afrea.jpg";
import eval4 from "../assets/images/eval4.png";
import evalpartners from "../assets/images/evalpartners.jpg";
import ioce from "../assets/images/ioce.png";
import gpfe from "../assets/images/gpfe.png";
import clear from "../assets/images/clear.jpg";
import rfe from "../assets/images/rfe.jpg";
import twende from "../assets/images/twende.png";
import unicef from "../assets/images/unicef.png";
import unw from "../assets/images/unw.png";
import undp from "../assets/images/undp.png";

import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { ReactComponent as Prev } from "../assets/svgs/slide-prev.svg";
import { ReactComponent as Next } from "../assets/svgs/slide-next.svg";
import SuccessCard from "../components/SuccessCard";
import Footer from "../components/Footer";
import SupportUs from "../components/SupportUs";
import { t } from "i18next";
import axios from "axios";
import Menu from "../components/Menu";
import SearchMain from "../components/SearchMain";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const [move, setMove] = useState(0);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(true);
  const [testimonials, setTestimonials] = useState([]);
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const lang = window.localStorage.getItem("apnode_lang");
  const [showSearch, setShowSearch] = useState(false);
  const location = useLocation();
  var element;

  if (location.hash) {
    console.log(location.hash);
    element = document.querySelector(location.hash);
  }

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [element]);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/testimonial/${lang}`,
    };
    axios(config)
      .then(function (response) {
        var res = response.data.results;
        setTestimonials(res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const objs = [
    {
      value: "01",
      text: t(
        "Sensitize national parliaments and parliamentarians about the importance of evaluation for oversight, policy and decision making"
      ),
    },
    {
      value: "02",
      text: t(
        "Enhance the capacity of parliamentarians to demand and utilize evaluation evidence in their parliamentary duties"
      ),
    },
    {
      value: "03",
      text: t(
        "Encourage parliaments to institutionalize evaluation to inform decision and policymaking"
      ),
    },
    {
      value: "04",
      text: t("Share experiences across Africa and beyond"),
    },
    {
      value: "05",
      text: t(
        "Support parliamentarians to ensure that country-level evaluations are responsive to the concerns of gender equality, vulnerable groups, and equitable development results"
      ),
    },
    {
      value: "06",
      text: t(
        "Assist MPs to embrace a culture of evaluation and evidence-based decision-making at all levels of government, and"
      ),
    },
    {
      value: "07",
      text: t(
        "Bridge the gap between parliamentarians (as users of evaluation evidence) and evaluators (as purveyors of impartial evidence)"
      ),
    },
  ];

  const logos = [
    {
      image: afrea,
      alt: "AfrEa",
      link: "https://afrea.org/",
    },
    {
      image: eval4,
      alt: "Eval4action",
      link: "https://www.eval4action.org/",
    },
    {
      image: evalpartners,
      alt: "Eval4apartners",
      link: "https://www.evalpartners.org/",
    },
    {
      image: ioce,
      alt: "ioce",
      link: "https://www.ioce.net/",
    },
    {
      image: gpfe,
      alt: "gpfe",
      link: "https://globalparliamentarianforum.wordpress.com/",
    },
    {
      image: clear,
      alt: "clear",
      link: "http://www.clear-aa.co.za/",
    },

    {
      image: rfe,
      alt: "rfe",
      link: "https://www.rfevaluation.org/",
    },
    {
      image: twende,
      alt: "twende",
      link: "http://www.twendembele.org/",
    },

    {
      image: unicef,
      alt: "unicef",
      link: "https://www.ungm.org/Shared/KnowledgeCenter/Pages/UNICEF",
    },
    {
      image: unw,
      alt: "unw",
      link: "https://www.unwomen.org/en",
    },

    {
      image: undp,
      alt: "undp",
      link: "https://www.undp.org/content/undp/en/home.html",
    },
  ];

  const comb = [
    {
      value: "01",
      text: t(
        "National Chapters shall be self-supporting and will need to identify local resources/partners to drive planned activities. The APNODE EC/Secretariat and collaborating partners (i.e., UNDP, UNICEF, UN-Women, Twende Mbele, EvalPartners, CLEAR-AA, etc.) will provide targeted support (e.g., human and knowledge resources, training, etc.,) as and when feasible;"
      ),
    },
    {
      value: "02",
      text: t(
        "APNODE’s Executive Committee and Secretariat will also provide letters of support to National Chapters in their efforts to fundraise if needed;"
      ),
    },
    {
      value: "03",
      text: t(
        "The National Chapter Treasurer shall ascertain the integrity of the financial records and, bi-annually, report to its constituent members and the APNODE Secretariat on the fiscal health of the Chapter;"
      ),
    },
    {
      value: "04",
      text: t(
        "National Chapter Treasurers will bi-annually collect and transmit all membership dues to the APNODE Secretariat;"
      ),
    },
    {
      value: "05",
      text: t(
        "National Chapters drawing on their nuancedknowledge of the local landscape, will determine the best-fit approaches to solicit and raise funds."
      ),
    },
  ];



  const govLeft = [
    {
      value: t("EXECUTIVE COMMITTEE"),
      text: t(
        "APNODE is led by an Executive Committee (EC), which is elected every other year at the AGM. Members serve a two- year term (renewable once). The EC oversees the strategic focus of APNODE and provides oversight on the day-to-day management of APNODE Network and Secretariat. The EC meets virtually on a bimonthly basis, and in person at least once a year. The EC is comprised of sitting members of parliament, APNODE founding members, and three representatives from APNODE partner institutions – AfDB, CLEAR (Centers for Learning on Evaluation and Results), and AfrEA (African Evaluation Association)."
      ),
      link: "/team",
    },

    {
      value: t("SECRETARIAT"),
      text: t(
        "The Secretariat is the administrative and operational arm of APNODE and executes the day-to-day operations of the Network under the guidance of the EC. In addition, the Secretariat administers the Network, ensures communication with the members, organizes the APNODE Annual General Meetings and coordinates the bimonthly virtual meetings of the APNODE Executive Committee. The Independent Development Evaluation (IDEV) at the AfDB, in line with its mandate to nurture an evaluation culture across the Bank’s Regional Member Countries, and as part of its evaluation capacity development work program, has hosted the Secretariat of APNODE since its inception."
      ),
    },
    {
      value: t("NATIONAL CHAPTERS"),
      text: t(
        "Chapters are semi-autonomous, member-driven bodies aimed at furthering APNODE’s primary objective of advancing and enhancing the uptake and utilization of evaluation in national decision-making to support development effectiveness and inclusive growth. APNODE chapters can be either national or regional and must be opened in a country where there is already at least one active APNODE member. The establishment of a national or regional chapter must be approved by the APNODE Executive Committee. Presently, there are four functional APNODE Chapters (Benin, Cameroon, Côte d’Ivoire and Zimbabwe)."
      ),
      link: "/membership#chapters",
    },
  ];

  const next = () => {
    if (move !== -672) {
      setRight(true);
      setLeft(true);
      const slider = document.getElementById("slider");
      slider.style.transform = `translateX(${move - 336}px`;
      setMove(move - 336);
    } else {
      setLeft(true);
      setRight(false);
    }
  };

  const prev = () => {
    if (move !== 0) {
      setLeft(true);
      setRight(true);
      const slider = document.getElementById("slider");
      slider.style.transform = `translateX(${move + 336}px`;
      setMove(move + 336);
    } else {
      setRight(true);
      setLeft(false);
    }
  };

  let menuBtn;

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    console.log("here");
    setShowSearch(true);
  };

  return (
    <div className="bg-white">
      {show && <Menu color="white" show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      <Navbar
        color="white"
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div>
        <img className="mx-auto w-screen" src={aboutus} />
      </div>
      <div className="xl:w-[1200px] w-full mx-auto grid lg:grid-cols-[1fr_1fr] block gap-[90px] px-5">
        <div className="about-bg lg:block hidden w-fit px-[30px] pt-[30px] translate-y-[-180px]">
          <img src={building} className="" />
        </div>
        <div className="lg:pt-[100px] pt-[40px]">
          <h2 className="font-sBold lg:text-4xl text-[28px]  uppercase">
            {t("Who We Are")}
          </h2>
          <p className="mt-[15px] lg:text-base ">
           {t('APNODE’s mandate is to strengthen the capacity of African parliamentarians for  quality oversight, policymaking, and national decision-making by ensuring  appreciation, demand, and utilization of evaluations in their daily work. To this end,  the Network serves as a platform via which members of parliament (MPs) share  information, knowledge and experiences on evidence use. APNODE also facilitates trainings, dialogue forums, learning events and networking opportunities for  parliamentarians; enables the participation of African MPs in international evaluation  forums; and, actively encourages knowledge capture for wider dissemination amongst  its stakeholders. Recognizing that parliamentarians are but one among many players  in national evaluation systems, APNODE strongly focuses on collaboration and  partnerships.')}
          </p>
        </div>
      </div>
      <div className="xl:w-[1200px] w-full mx-auto lg:grid lg:grid-cols-[1fr_1fr]  block  gap-[90px] px-5  ">
        <div className="lg:pl-[30px]">
          <img className="mt-10 hidden lg:block" src={vision} />
        </div>
        <div className="mt-10">
          <h2 className="font-sBold lg:text-4xl text-[28px]  mb-[15px]">
            {t("VISION")}
          </h2>
          <p className="lg:text-base">
            {t(
              "APNODE’s vision is to promote development effectiveness and inclusive growth on the African continent through an effective use of development evaluation by policy and decision makers."
            )}
          </p>
        </div>
      </div>
      <div className="xl:w-[1200px] w-full mx-auto lg:grid lg:grid-cols-[1fr_1fr] block gap-[60px] px-5 mt-[88px]">
        <div>
          <h2 className="font-sBold lg:text-4xl text-[28px]  mb-[30px]">
            {t("OBJECTIVES")}
          </h2>
          <ol className="lg:w-[450px] w-full">
            {objs.map((ele, i) => (
              <li className="flex mb-[30px]" value={ele.value}>
                <span className="mr-[23px] text-2xl text-buttonColor font-sMedium">
                  {ele.value}
                </span>
                <span className=" text-black">{ele.text}</span>
              </li>
            ))}
          </ol>
        </div>
        <div>
          <div className="relative ">
            <img src={objectives} className="lg:block hidden" />
          </div>
        </div>
      </div>
      <div  id="whatwedo" className="xl:w-[1200px] w-full mx-auto lg:grid lg:grid-cols-[1fr_1fr] block gap-[60px] px-5 mt-[88px] mb-10">
        <div>
          <h2 className="font-sBold lg:text-4xl text-[28px]  mb-[15px] ">
            {t("WHAT WE DO")}
          </h2>
          <p>
            {t(
              "APNODE’s mandate is to strengthen the capacity of African parliamentarians for quality oversight, policymaking, and national decision-making by ensuring appreciation, demand, and utilization of evaluations in their daily work. To this end, the Network serves as a platform via which members of parliament (MPs) share information, knowledge and experiences on evidence use. APNODE also facilitates trainings, dialogue forums, learning events and networking opportunities for parliamentarians; enables the participation of African MPs in international evaluation forums; and, actively encourages knowledge capture for wider dissemination amongst its stakeholders. Recognizing that parliamentarians are but one among many players in national evaluation systems, APNODE strongly focuses on collaboration and partnerships."
            )}
            <br />
            <br />
            {t(
              "APNODE systematically gathers, collates, repackages and disseminates information and knowledge on evidence-use to its members via its Weekly What’s New Flyer, LinkedIn Group platform, Quarterly Newsletter and website. We partner with the African Evaluation Association (AfrEA), Eval4Action, Centers for Learning on Evaluation and Results (CLEAR-AA/FA), Reseau Francophone d’Evaluation (RFE), Twende Mbele, UNICEF and UN-Women to develop the capacity of African MPs, and to facilitate their participation in national, regional and global conferences and events. We further support the establishment of APNODE chapters at the national level to enhance and advance the evolution of an evaluation culture."
            )}
          </p>
        </div>
        <div>
          <img className="lg:block hidden w-[100%]" src={we_do} />
        </div>
      </div>
      {/* <div className="bg-secondBg lg:py-[76px] py-4">
        <div className="xl:w-[1200px] w-full mx-auto md:grid md:grid-cols-[1fr_1fr] block gap-[20px]  px-5 lg:mt-[88px] mt-[20px]">
          <img className="mt-0 md:block hidden" src={a3} />
          <div>
            <h3 className="appPText mb-[15px] lg:text-4xl text-[28px]  font-sBold uppercase">
              {t(
                "APNODE Governance Structure -  AGM, Executive Committee, Secretariat, National Chapters"
              )}
            </h3>
            <p className="text-black  mb-[24px]">
              {t(
                "National Chapters are an integral part of APNODE and mirror the Network’s core values and vision, advocacy/outreach, capacity building, communication and resource mobilization strategies. Presently, there are 3 active APNODE National Chapters (i.e., Cameroon, Cote d’Ivoire, and Zimbabwe). Given the fluidity of parliamentarians’ positions, membership of National Chapters tends to fluctuate with election cycles. Accordingly, efforts are ongoing in Benin, South Sudan, Zambia to launch new, or rebuild National Chapters."
              )}
            </p>
            <a
              href="/membership"
              className="bg-buttonColor  text-white px-[30px] py-[9px] text-[17px]"
            >
              {t("Learn More")}
            </a>
          </div>
        </div>
      </div> */}
      <div className="bg-black mt-[100px] lg:px-4">
        <div className=" lg:w-[1000px] block   gap-[40px] w-full mx-auto  pt-[40px]  xl:gap-[80px]  lg:px-0 px-5 pb-2">
          <div>
            <h3 className="text-navbarBg text-center mb-10 lg:text-[36px] text-[25px]  uppercase font-sBold">
              {t("GOVERNANCE STRUCTURE")}
            </h3>
          </div>
          <div className="block">
            <div className=" w-fit   mb-[30px]">
              <li className="block">
                <p className="mr-[14px] lg:text-[23px] text-[20px] text-navbarBg font-raleway font-bold ">
                  {t("ANNUAL GENERAL MEETING")}
                </p>
                <span className=" text-white font-roboto">
                  {t(
                    "The Annual General Meeting (AGM) is APNODE’s highest decision-making body and convenes annually to consider pertinent issues/decisions of the year-in-review. The mandatory yearly gathering is required by the Network’s constitution and serves as a meeting of the general membership of the Network to both reaffirm their commitment to APNODE, as well as to help steer the ship with policy consensus. The first AGM of the Network, hosted by the AfDB in Abidjan on 23 July 2015, resulted in the adoption of an APNODE Constitution. Subsequent AGMs in Harare (2016), Khartoum (2017), Libreville (2018) and once again in Abidjan (2019), have contributed in no small measure to enhancing the Networks’ visibility and institutionalizing its systems and processes. Due to the COVID-19 pandemic, no AGM was held in 2020. In 2021, with COVID waning, the Network and its partners, opted to hold the AGM virtually, which was hosted by the AfDB."
                  )}
                </span>
              </li>
            </div>
            <div className="">
              {govLeft.map((ele, i) => (
                <li key={i} className="block mb-[30px]" value={ele.value}>
                  <p className="mr-[14px] lg:text-[23px] text-[20px] text-navbarBg font-raleway font-bold ">
                    {ele.value}
                  </p>
                  <p className=" text-white font-roboto">{ele.text}</p>{" "}
                  {ele.link && (
                    <a
                      href={ele.link}
                      className="border border-buttonColor mt-2 block px-[30px] py-[9px] w-fit text-white lg:text-base text-[14px]"
                    >
                      {t("Learn More")}
                    </a>
                  )}
                </li>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="py-[70px] xl:w-[1200px] lg:w-[950px] md:w-[700px] w-full mx-auto">
        <h3 className="text-center lg:text-[38px] uppercase text-[25px] font-sBold text-black">
          {t("Success Stories")}
        </h3>

        <div className="flex items-center xl:mt-24 mt-10 justify-between">
          <Prev
            stroke={`${left ? "black" : "grey"}`}
            onClick={prev}
            className="cursor-pointer lg:w-[100px] w-[30px]"
          />
          <div className=" w-full  overflow-hidden">
            <div id="slider" className="flex  lg:gap-[40px] gap-[0px]  transition-all">
              {testimonials.map((ele, i) => (
                <SuccessCard key={i} data={ele} />
              ))}
            </div>
          </div>
          <Next
            stroke={`${right ? "black" : "grey"}`}
            className="cursor-pointer  lg:w-[100px] w-[30px]"
            onClick={next}
          />
        </div>
      </div>
      <SupportUs />
      <Footer />
    </div>
  );
};

export default AboutUs;
