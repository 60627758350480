import React, { useState, useEffect } from "react";
import m1 from "../assets/images/m1.jpg";
import m2 from "../assets/images/m2.jpg";
import m3 from "../assets/images/m3.jpg";
import we_do from "../assets/images/we_do.jpg";
import vision from "../assets/images/vision.jpg";
import we from "../assets/images/we.jpg";
import admin from "../assets/images/admin.jpg";
import who_can from "../assets/images/who_can.JPG";
import chapter1 from "../assets/images/chapter1.png";
import chapter2 from "../assets/images/chapter2.png";
import c1 from "../assets/images/c1.png";
import c2 from "../assets/images/c2.png";
import c3 from "../assets/images/c3.png";
import Navbar from "../components/Navbar";
import axios from "axios";
import Footer from "../components/Footer";
import { t } from "i18next";
import Menu from "../components/Menu";
import { useLocation } from "react-router-dom";
import { $CombinedState } from "@reduxjs/toolkit";
import Interested from "../components/Interested";
import SearchMain from "../components/SearchMain";
import CountryModal from "../components/CountryModal";
const Membership = () => {
  const left = [
    {
      value: "02",
      text: t(
        "APNODE’s Executive Committee and Secretariat will also provide letters of support to National Chapters in their efforts to fundraise if needed;"
      ),
    },
    {
      value: "04",
      text: t(
        "National Chapter Treasurers will bi-annually collect and transmit all membership dues to the APNODE Secretariat;"
      ),
    },
  ];

  const right = [
    {
      value: "03",
      text: t(
        "The National Chapter Treasurer shall ascertain the integrity of the financial records and, bi-annually, report to its constituent members and the APNODE Secretariat on the fiscal health of the Chapter;"
      ),
    },
    {
      value: "05",
      text: t(
        "National Chapters drawing on their nuancedknowledge of the local landscape, will determine the best-fit approaches to solicit and raise funds."
      ),
    },
  ];

  const comb = [
    {
      value: "01",
      text: t(
        "National Chapters shall be self-supporting and will need to identify local resources/partners to drive planned activities. The APNODE EC/Secretariat and collaborating partners (i.e., UNDP, UNICEF, UN-Women, Twende Mbele, EvalPartners, CLEAR-AA, etc.) will provide targeted support (e.g., human and knowledge resources, training, etc.,) as and when feasible;"
      ),
    },
    {
      value: "02",
      text: t(
        "APNODE’s Executive Committee and Secretariat will also provide letters of support to National Chapters in their efforts to fundraise if needed;"
      ),
    },
    {
      value: "03",
      text: t(
        "The National Chapter Treasurer shall ascertain the integrity of the financial records and, bi-annually, report to its constituent members and the APNODE Secretariat on the fiscal health of the Chapter;"
      ),
    },
    {
      value: "04",
      text: t(
        "National Chapter Treasurers will bi-annually collect and transmit all membership dues to the APNODE Secretariat;"
      ),
    },
    {
      value: "05",
      text: t(
        "National Chapters drawing on their nuancedknowledge of the local landscape, will determine the best-fit approaches to solicit and raise funds."
      ),
    },
  ];
  let menuBtn;

  const lang = window.localStorage.getItem("apnode_lang");
  const [chapters, setChapters] = useState([]);
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [institutionalMembers, setInstitutionalMembers] = useState([]);
  const [parliaments, setParliaments] = useState([]);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [content, setContent] = useState("");

  const location = useLocation();
  var element;

  if (location.hash) {
    element = document.querySelector(location.hash);
  }

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        top: 100,
      });
    }
  }, [element]);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/institutional-members/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setInstitutionalMembers(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/parliament-members/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setParliaments(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `/v1/apnode/general/national-chapters/${lang}`,
    };
    axios(config)
      .then(function (response) {
        setChapters(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const truncate = (str) => {
    return str.length > 300 ? str.substring(0, 295) + "..." : str;
  };

  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      console.log("else");

      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  const handleCountryShow = (country, content) => {
    setShowCountryModal(true);
    setSelectedCountry(country);
    setContent(content);
  };

  return (
    <div>
      {show && <Menu show={show} handleShow={handleShow} />}
      {showSearch && (
        <SearchMain
          setShowSearch={() => setShowSearch(false)}
          show={showSearch}
        />
      )}
      {showCountryModal && (
        <CountryModal
          country={selectedCountry}
          setShowCountryModal={() => setShowCountryModal(false)}
          content={content}
        />
      )}
      <Navbar
        setShowSearch={() => setShowSearch(false)}
        showMenu={show}
        handleShowSearch={handleShowSearch}
        handleShow={handleShow}
      />{" "}
      <div className="bg-navbarBg">
        <div
          id="join"
          className=" xl:w-[1200px] lg:w-[950px] w-full mx-auto xl:px-0 px-5 pt-[0px] "
        >
          <div className="xl:grid xl:grid-cols-[53%_47%] block  pl-0 pt-5 ">
            <div className="xl:w-[530px] w-full flex flex-col ">
              <div>
                <h1 className="xl:text-[36px] uppercase  lg:text-[38px] text-[1.5rem] font-sBold xl:text-left  text-center leading-10">
                  {t("Who can join APNODE?")}
                </h1>
                <p className="font-normal mt-[0px] text-black lg:text-base text-[14px]">
                  {t(
                    "The Network is open to current and former parliamentarians from Africa and beyond, African  national and regional parliaments, non-stake actors (i.e., civil society, private sector,  research/policy institutes, national and regional evaluation associations) as well as other  individuals and organizations interested in APNODE’s work and vision. There are five  categories of membership, as defined in its constitution, notably:"
                  )}
                  <ol className="text-black pl-[20px] leading-6 xl:text-base text-sm grid grid-cols-2">
                    <li className="history-list list-disc">
                      {t("Founding members")}
                    </li>
                    <li className="history-list list-disc">
                      {t("Full members")}
                    </li>
                    <li className="history-list list-disc">
                      {t("Associate members")}
                    </li>
                    <li className="history-list list-disc">
                      {t("Affiliate members")}
                    </li>
                    <li className="history-list list-disc">{t("Partners")}</li>
                  </ol>
                  <p className="">
                    {t(
                      "Details on each  category and associated membership fee can be found in the "
                    )}
                    <a
                      className="text-buttonColor"
                      href={
                        lang == "en"
                          ? "https://docs.apnode.org/media/images/official-doc/1667510109.0044568_APNODE_MEMBERSHIP_POLICY_-_ENG_APNODE_MEMBERSHIP__yPPRfjY.pdf"
                          : "https://docs.apnode.org/media/images/official-doc/1667510144.5530086_APNODE_Politique_d_adhesion_-_FR_APNODE_Politique_KP9DGfl.pdf"
                      }
                    >
                      {t("Membership Policy.")}
                    </a>
                  </p>
                </p>
              </div>
              <a
                href="/membership/form"
                className="bg-buttonColor text-white px-[30px] py-[9px] lg:text-[17px] text-[14px] w-fit xl:mt-2 mt-4 mb-5 xl:ml-0 mx-auto "
              >
                {t("Become a Member")}
              </a>
            </div>
            <div className="xl:relative xl:block hidden">
              <img className="relative z-[4] ml-auto " src={who_can} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white xl:w-[1200px] lg:w-[950px] block lg:grid grid-cols-2 mx-auto lg:mt-[100px] mt-[20px] gap-[70px]">
        <div className="w-full  xl:px-0 px-4">
          <div className="max-w-[416px]">
            <h2
              id="why"
              className="font-sBold text-left  uppercase lg:text-[36px] text-[25px] "
            >
              {t("Why Join APNODE?")}
            </h2>
            <p className="text-appText font-sLight font- text-[15px]">
              {t("APNODE offers a host of benefits to its members.")}
            </p>
          </div>

          {/* main */}
          <div className=" max-w-full lg:block hidden mt-4 ">
            <div className="px-[32px] pl-4 bg-black text-white  py-[48px] font-raleway">
              <div className="flex items-center font-bold text-2xl mb-4">
                <span className="text-appNumber text-[30px]  mr-[19px]">
                  01
                </span>
                <h6 className="text-white">{t("Shared Knowledge Based")}</h6>
              </div>
              <p className="font-roboto text-white pl-[55px] ">
                {t(
                  "Access to a shared knowledge base through contact with like-minded professionals is a key pull factor that attracts many to APNODE."
                )}
              </p>
            </div>

            <div className="px-[32px] pl-4 bg-appText text-white mt-10 pl-0 py-[48px] font-raleway">
              <div className="flex items-center font-bold text-2xl mb-4">
                <span className="text-white text-[30px]  mr-[19px]">03</span>
                <h6 className="text-white"> {t("Knowledge-sharing")}</h6>
              </div>
              <p className=" font-roboto text-white pl-[55px]">
                {t(
                  "Members automatically subscribe to, and receive APNODE’s electronic Weekly What’s New flyer, as well as the Quarterly Newsletter, both of which offer them invaluable knowledge and access to learning events, dialogue forums, and news. Aside from these, members’ participation in APNODE facilitated trainings, workshops, and conferences further encourage knowledge-sharing and peer learning."
                )}
              </p>
            </div>

            <div className="px-[32px] pl-4 bg-buttonColor text-white mt-10  py-[48px] font-raleway">
              <div className="flex items-center font-bold text-2xl mb-4">
                <span className="text-appNumber text-[30px]  mr-[19px]">
                  05
                </span>
                <h6 className="text-white">
                  {t("Experience-sharing and peer-learning")}
                </h6>
              </div>
              <p className="font-roboto text-white pl-[55px] ">
                {t(
                  "Members benefit immensely from the experience-sharing and peer-learning that constantly takes place. Lessons learned, innovative, and fit-for-purpose solutions to common problems and challenges are regularly exchanged. Going forward, the Network intends to experiment with an APNODE National Chapters exchange program, where members from one or more National Chapters may visit and learn from one another."
                )}
              </p>
            </div>
          </div>
          <div className="lg:max-w-[504px] lg:hidden block w-full  ">
            <div className="bg-navbarBg ">
              <div className="lg:px-[32px] px-[18px] lg:py-[48px] py-[20px] font-raleway">
                <div className="flex items-center font-bold text-2xl mb-4">
                  <span className="text-appNumber text-[30px]  mr-[19px]">
                    01
                  </span>
                  <h6 className="text-appPText">{t("Knowledge-sharing")}</h6>
                </div>
                <ul className="lg:pl-[55px] pl-[20px] lg:text-base text-[14px] ">
                  <li className="font-roboto text-appText history-list list-disc ">
                    {t(
                      "Access to a shared knowledge base through contact with like-minded professionals is a key pull factor that attracts many to APNODE."
                    )}
                  </li>
                  <br />
                  <li className=" font-roboto text-appText history-list list-disc">
                    {t(
                      "Members automatically subscribe to, and receive APNODE’s electronic Weekly What’s New flyer, as well as the Quarterly Newsletter, both of which offer them invaluable knowledge and access to learning events, dialogue forums, and news. Aside from these, members’ participation in APNODE facilitated trainings, workshops, and conferences further encourage knowledge-sharing and peer learning."
                    )}
                  </li>
                </ul>
              </div>
            </div>

            <div className="bg-white lg:max-w-[504px] w-full  mt-4">
              <div className=" bg-black text-white   lg:px-[32px] px-[18px] lg:py-[48px] py-[20px] font-raleway">
                <div className="flex items-center font-bold text-2xl mb-4">
                  <span className="text-appNumber text-[30px]  mr-[19px]">
                    02
                  </span>
                  <h6 className="">{t("Networking")}</h6>
                </div>
                <p className="lg:pl-[55px] pl-[10px] lg:text-base text-[14px] font-roboto ">
                  {t(
                    "Members leverage the platform to discuss and exchange on issues pertaining to evaluation, parliaments, parliamentary oversight, governance, etc., be it virtually on the APNODE"
                  )}
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/groups/8734908/"
                    className="text-buttonColor"
                  >
                    {t("LinkedIn group")}
                  </a>
                  ,
                  <a
                    target="_blank"
                    href="https://twitter.com/APNODE_Official"
                    className="text-buttonColor"
                  >
                    {"Twitter page"}
                  </a>
                  ,
                  {t(
                    "via the APNODE WhatsApp platform, in person during the APNODE AGMs, National Chapter events, and/or at APNODE partners’ knowledge forums and learning events."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full xl:px-0 px-4 lg:block hidden ">
          <div className="max-w-[416px]  invisible">
            <h2 className="font-sBold text-left  uppercase lg:text-[36px] text-[25px] ">
              {t("Why Join APNODE?")}
            </h2>
            <p className="text-appText font-sLight font- text-[15px]">
              {t("APNODE offers a host of benefits to its members.")}
            </p>
          </div>
          <div className="bg-navbarBg ">
            <div className="px-[32px] py-[48px] font-raleway mt-4">
              <div className="flex items-center font-bold text-2xl mb-4">
                {/* main */}
                <span className="text-appNumber text-[30px]  mr-[19px]">
                  02
                </span>
                <h6 className="">{t("Networking")}</h6>
              </div>
              <p className="lg:pl-[55px] pl-[10px] lg:text-base text-[14px] font-roboto ">
                {t(
                  "Members leverage the platform to discuss and exchange on issues pertaining to evaluation, parliaments, parliamentary oversight, governance, etc., be it virtually on the APNODE "
                )}
                <a
                  target="_blank"
                  href="https://www.linkedin.com/groups/8734908/"
                  className="text-buttonColor"
                >
                  {t("LinkedIn group")}
                </a>
                , {" "}
                <a
                  target="_blank"
                  href="https://twitter.com/APNODE_Official"
                  className="text-buttonColor"
                >
                  {"Twitter page"}
                </a>
                ,{" "}
                {t(
                  "via the APNODE WhatsApp platform, in person during the APNODE AGMs, National Chapter events, and/or at APNODE partners’ knowledge forums and learning events."
                )}
              </p>
            </div>
          </div>
          <div className="bg-black w-full xl:px-0 px-4  mt-10">
            <div className="px-[32px] py-[48px] font-raleway">
              <div className="flex items-center font-bold text-2xl mb-4">
                {/* main */}
                <span className="text-appNumber text-[30px]  mr-[19px]">
                  04
                </span>
                <h6 className="text-white"> {t("Capacity development")}</h6>
              </div>
              <p className="pl-[55px] font-roboto text-white">
                {t(
                  "Membership status makes available an extensive array of capacity development opportunities and possibilities, notably webinars, resource handbooks, databases, and digital applications. Members can also attend and participate in regional and global forums, trainings and learning events, as well as represent the Network in meetings, workshops, and conferences both across the African continent and beyond. Additionally, members get access of APNODE strategic partners’ knowledge and learning resources and technical expertise."
                )}
              </p>
            </div>
          </div>

          <div className="bg-white lg:max-w-[504px] w-full  mt-4">
            <div className="px-[32px] py-[48px] font-raleway">{/* main */}</div>
          </div>
        </div>

        <div className="lg:max-w-[504px]  lg:hidden block w-full px-4 ">
          <div className="bg-white lg:max-w-[504px] w-full  mt-4">
            <div className="lg:px-[32px] px-[18px] lg:py-[48px] py-[20px] font-raleway">
              <div className="flex items-center font-bold text-2xl mb-4">
                <span className="text-appNumber text-[30px]  mr-[19px]">
                  03
                </span>
                <h6 className="text-appPText">
                  {" "}
                  {t("Experience-sharing and peer-learning")}
                </h6>
              </div>
              <p className="lg:pl-[55px] pl-[5px] lg:text-base text-[14px] font-roboto text-appText">
                {t(
                  "Members benefit immensely from the experience-sharing and peer-learning that constantly takes place. Lessons learned, innovative, and fit-for-purpose solutions to common problems and challenges are regularly exchanged. Going forward, the Network intends to experiment with an APNODE National Chapters exchange program, where members from one or more National Chapters may visit and learn from one another."
                )}
              </p>
            </div>
          </div>

          <div className=" bg-appText text-white mt-10  lg:px-[32px] px-[18px] lg:py-[48px] py-[20px] font-raleway">
            <div className="flex pl-4 items-center font-bold text-2xl mb-4">
              <span className="text-white text-[30px]  mr-[19px]">04</span>
              <h6 className="">{t("Capacity development")}</h6>
            </div>
            <p className="lg:pl-[55px] pl-[5px] lg:text-base text-[14px] font-roboto ">
              {t(
                "Membership status makes available an extensive array of capacity development opportunities and possibilities, notably webinars, resource handbooks, databases, and digital applications. Members can also attend and participate in regional and global forums, trainings and learning events, as well as represent the Network in meetings, workshops, and conferences both across the African continent and beyond. Additionally, members get access of APNODE strategic partners’ knowledge and learning resources and technical expertise."
              )}
            </p>
          </div>
        </div>
      </div>
      <div
        id="parliament"
        className="xl:w-[1200px] lg:w-[950px] w-full mx-auto gap-[60px] px-5 mt-[88px]"
      >
        <div>
          <div>
            <h3 className="appPText mb-[15px] lg:text-4xl text-[28px]  mx-auto  text-center font-sBold uppercase">
              {t("Institutional Member Parliaments")}
            </h3>
            {/* <p className="text-black text-center mb-[24px]">
              {t(
                "Open to African National Parliaments  and Regional Parliaments"
              )}
            </p> */}
          </div>
        </div>
        <div className=" justify-center mx-auto grid grid-cols-3 px-2 items-center justify-center gap-5  mb-10  mt-4">
          {institutionalMembers.length
            ? institutionalMembers.map((ele, i) => (
                <div>
                  <a
                    key={i}
                    href={ele.link}
                    className="mb-4 block"
                    target="_blank"
                  >
                    <img
                      src={ele.image}
                      title={ele.title}
                      className="lg:w-[136px] w-[80px] h-[80px] mx-auto h-auto"
                    />
                  </a>
                  <p className="text-sm  text-center ">{ele.title}</p>
                </div>
              ))
            : null}
        </div>
      </div>
      <div className="xl:w-[1200px] lg:w-[950px] w-full mx-auto  gap-[60px] px-5 mt-[88px]">
        <div>
          <div>
            <h3 className="appPText mb-[15px] lg:text-4xl text-[28px]   text-center font-sBold uppercase">
              {t("Parliaments with APNODE Members")}
            </h3>
            <p className="text-appText mb-[24px]"></p>
          </div>
        </div>
        <div className="grid grid-cols-3 px-2 items-center justify-center gap-5  mb-10  mt-4">
          {parliaments.length
            ? parliaments.map((ele, i) => (
                <div>
                  <a
                    key={i}
                    href={ele.link}
                    className="mb-4 block "
                    target="_blank"
                  >
                    <img
                      src={ele.image}
                      title={ele.title}
                      className="lg:w-[136px] w-[80px] h-[80px] mx-auto "
                    />
                  </a>
                  <p className="text-sm text-center">{ele.title}</p>
                </div>
              ))
            : null}
        </div>
      </div>
      <div
        id="chapters"
        className="xl:w-[1200px] lg:w-[950px] w-full mx-auto px-4 lg:pt-[120px] pt-[60px]"
      >
        <h1 className="xl:text-[36px] lg:text-[34px] text-[25px] uppercase  text-center font-sBold">
          {t("APNODE NATIONAL CHAPTERS")}
        </h1>
        <p className="text-center lg:w-[863px] w-full mx-auto mt-5">
          {t("Each National Chapter shall operate in accordance with")}{" "}
          <a
            className="text-buttonColor"
            href={
              lang === "fr"
                ? "https://sfo3.digitaloceanspaces.com/apnode/media/images/official-doc/1667510244.6189182_APNODE_Note_d_orientation_FIN_APNODE_Note_d_orien_5OCRGVR.pdf"
                : "https://sfo3.digitaloceanspaces.com/apnode/media/images/official-doc/1667510205.7147753_APNODE_National_Chapter_Guidelines_-_FINAL_APNODE_0VjQXHd.pdf"
            }
            download
          >
            {t("APNODE's National Chapter Guidelines")}
          </a>{" "}
          {t(
            "and in accordance with an agreement signed by the National Chapter and the APNODE Executive Committee."
          )}
        </p>

        <span className="text-black font-sBold mt-[30px]  text-center mx-auto mb-[48px]  w-fit  uppercase pb-1 border-b-black block ">
          {t("Case Studies")}
        </span>
        <div className="xl:grid xl:grid-cols-4 lg:grid lg:grid-cols-3 gap-4 flex-wrap justify-center">
          {chapters.map((ele, i) => (
            <div
              key={i}
              className="pt-[30px] text-[14px] leading-6 px-[22px] pb-[20px] bg-navbarBg w-full mx-auto mb-3 text-appText relative self-stretch auto-rows-fr	"
            >
              <div className="flex justify-start  items-center mb-[22px] ">
                <img
                  className="w-[60px] h-[60px] rounded-full"
                  src={ele.image}
                  alt={ele.title}
                />
                <h4 className="ml-4 text-black text-xl font-bold">
                  {" "}
                  {ele.title}
                </h4>
              </div>
              <p className="mb-4 h-[240px]">{truncate(ele.description)}</p>
              <button
                onClick={() => handleCountryShow(ele.title, ele.content)}
                className="text-buttonColor mt-4 text-centerfont-sMedium w-fit  border-b-2 pb-1 border-b-buttonColor block cursor-pointer "
                href={ele.link}
              >
                {t("Learn More")}
              </button>
            </div>
          ))}
        </div>
      </div>
      <h1
        id="steps"
        className="xl:text-[36px] lg:text-[34px] text-[25px] uppercase lg:text-center  text-center font-sBold lg:mt-20 mt-12 lg:mb-8 mb-5 px-4"
      >
        {t("Steps to setting up an APNODE National Chapter")}
      </h1>
      <div className=" xl:w-[1200px] w-full mx-auto px-4 pt-[16px]">
        <div className="xl:grid xl:grid-cols-[55%_35%] block gap-[0px]">
          <div className="xl:block hidden">
            <img src={we_do} className="w-[500px]" />
          </div>
          <div className="xl:px-0 px-4">
            <p className="lg:text-2xl text-[20px] text-appPText font-sBold mb-[15px]">
              {t("APPLYING TO SET UP A NATIONAL CHAPTER")}
            </p>
            <p className=" text-appText mb-[15px]">
              {t(
                "Any group of APNODE members (sitting and/or former parliamentarians and associate members) wishing to obtain the status of National Chapter must first:"
              )}
            </p>
            <ul className="text-appText leading-6">
              <li className="history-list list-disc">
                {t(
                  "Confirm with the APNODE Secretariat that there is no existing APNODE National Chapter (or pending application) for the proposed country, as only ONE National Chapter can be recognized per country;"
                )}
              </li>
              <li className="history-list list-disc">
                {t(
                  "Submit a brief letter to the APNODE Executive Committee (via the Secretariat) seeking permission to set up an APNODE National Chapter. If the Executive Committee agrees, it will include the approval of the Chapter in the agenda of the next AGM;"
                )}
              </li>
              <li className="history-list list-disc">
                {t(
                  "Provide a national physical address/contact information within a recognized institution where the National Chapter holds its administrative affairs. The institution must have the resources and infrastructure to host/support the National Chapter. Members of the National Chapter can select the preferred hosting institution;"
                )}
              </li>
              <li className="history-list list-disc">
                {t(
                  "List the members of the proposed National Chapter, all of whom should be registered and paid-up members of APNODE. A minimum of SEVEN members is required to set up a National Chapter. The names of local ‘sympathisers’ should also be provided;"
                )}
              </li>
              <li className="history-list list-disc">
                {t(
                  "Demonstrate that there are clear objectives/rules for the selection of officers and control of finances; and"
                )}
              </li>
              <li className="history-list list-disc">
                {t(
                  "Designate/provide the contact details of a focal person responsible for liaising with the APNODE Secretariat. All subsequent National Chapter members must also be registered and paid-up members of APNODE."
                )}
              </li>
            </ul>
          </div>
        </div>

        <div className="xl:grid xl:grid-cols-[35%_55%] block gap-[0px] mt-[70px]">
          <div className="xl:px-0 px-4">
            <p className="text-2xl uppercase font-sBold text-appPText mb-[15px]">
              {t("CHAPTER ADMINISTRATION")}
            </p>

            <p className=" text-appText mb-[15px]">
              {t(
                "After receiving the AGM’s approval to set up a National Chapter, the group will submit the following to the APNODE Chair and APNODE Secretariat:"
              )}
            </p>
            <ul className="text-appText leading-6">
              <li className="history-list list-disc">
                {t(
                  "The names, designations, and contact details of a leadership team comprised of a National Chapter Chairperson, Vice-Chairperson, Secretary, and Treasurer with an agreed term of office (renewable once);"
                )}
              </li>
              <li className="history-list list-disc">
                {t(
                  "At least two members of the leadership team must be sitting parliamentarians;"
                )}
              </li>
              <li className="history-list list-disc">
                {t(
                  "Tentative dates and place of their planned regular meetings (at least quarterly), and"
                )}
              </li>
              <li className="history-list list-disc">
                {t(
                  "A list of its projected activities for the calendar year. The Chapter should maintain a list of all members with their names, designations and contact details."
                )}
              </li>
            </ul>
          </div>
          <div className="xl:block hidden">
            <img className="ml-auto w-[540px] aspect-video" src={admin} />
          </div>
        </div>
      </div>
      <Interested link="/membership/form" />
      <Footer />
    </div>
  );
};

export default Membership;
