import React from "react";

const SeeAllCard = ({ data, type }) => {
  const truncate = (str) => {
    return str.length > 15 ? str.substring(0, 13) + "..." : str;
  };

  const convertDate = (data) => {
    const date = new Date(data);
    const res = `${date.toDateString().split(" ")[1]} ${
      date.toDateString().split(" ")[3]
    }`;
    return res;
  };

  return (
    <a
      target="_blank"
      href={`
    ${
        `${data.link ? data.link : data.document}`
    }
  `}
      class="flex flex-col items-center bg-white border rounded-lg shadow-md md:flex-row  w-full mb-4"
    >
      <img className="w-[150px] h-[200px]" src={data.thumbnail} />
      <div class="flex flex-col justify-between p-4 leading-normal">
        <p class="mb-3 font-normal text-black">
          {/* {truncate(data.slug)} */}
          {data.title}
        </p>
        <p className="text-buttonColor">{convertDate(data.date)}</p>
      </div>
    </a>
  );
};

export default SeeAllCard;
