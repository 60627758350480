import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import fr from "../assets/locales/fr/translation.json";
import en from "../assets/locales/en/translation.json";
const resources = {
  en: { translation: en },
  fr: { translation: fr },
};
const lang = window.localStorage.getItem("apnode_lang");

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)

  .init({
    resources,
    lng: lang,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
